import Modal from '../ModalCustom'
import { useState } from 'react';
import TextInputCustom from '../TextInputCustom'
import './index.scss'
import ButtonCustom from '../ButtonCustom';
import { Button } from 'react-bootstrap';
import { passValidation } from '../../utils/validation';
import { useStorage } from '../../Providers/StorageProvider';
import { useAuth } from '../../Providers/AuthProvider';

export default ({ visible, closeFunc }) => {
  const { requestsToApi } = useAuth();
  const { userName } = useStorage();
  const [inputValues, setInputValues] = useState({
    oldPass: '',
    newPass: '',
    repeatNewPass: ''
  });
  const [validStatus, setValidStatus] = useState({
    oldPass: null,
    newPass: null,
    repeatNewPass: null
  })

  const changeInputValue = (key) => (value) => {
    setInputValues({ ...inputValues, [key]: value })
  }

  const repeatNewPassValidation = () => inputValues.newPass === inputValues.repeatNewPass;
  const newPassValidation = () => {
    return (inputValues.newPass !== inputValues.oldPass) &&
      passValidation(inputValues.newPass);
  }

  const blockSubmit = (title, validationFunc) => {
    let isValid = validationFunc();
    setValidStatus({ ...validStatus, [title]: isValid })
    return isValid
  }

  const submitToggle = async () => {
    try {
      if (validStatus.oldPass && validStatus.newPass && validStatus.repeatNewPass) {
        const response = await requestsToApi.put(`agency/${userName}/account/user/hkdsa/setting/password`, {
          password: inputValues.newPass,
          oldPassword: inputValues.oldPass
        })
        closeFunc();
      } else {
        //ShowMessage
      }
    } catch (error) {
      console.log(error?.message)
    }
  }

  return (
    <Modal className="modal-form" visible={visible} closeFunc={closeFunc} width="500px">
      <div className="modal-form__content">

        <h1>Change Password</h1>
        <TextInputCustom
          label='Old Password'
          type='password'
          color='white'
          onChange={changeInputValue('oldPass')}
          checkFunc={() => blockSubmit("oldPass", () => passValidation(inputValues.oldPass))}
        >{inputValues.oldPass}
        </TextInputCustom>
        <TextInputCustom
          label='New Password'
          type='password'
          color='white'
          onChange={changeInputValue('newPass')}
          checkFunc={() => blockSubmit("newPass", newPassValidation)}
        >{inputValues.newPass}
        </TextInputCustom>
        <TextInputCustom
          label='Repeat Password'
          type='password'
          color='white'
          onChange={changeInputValue('repeatNewPass')}
          checkFunc={() => blockSubmit("repeatNewPass", repeatNewPassValidation)}
          autoComplete="off"
        >{inputValues.repeatNewPass}
        </TextInputCustom>

        {/* <div style={{ width: "20px", height: "20px", color: `${validStatus.oldPass ? "green" : "red"}` }} >1</div>
                <div style={{ width: "20px", height: "20px", color: `${validStatus.newPass ? "green" : "red"}` }} >2</div>
                <div style={{ width: "20px", height: "20px", color: `${validStatus.repeatNewPass ? "green" : "red"}` }} >3</div> */}
        <div className="modal-form__controls">
          <Button
            className="cansel-button"
            variant="link"
            onClick={() => closeFunc(false)}
          >
            Cancel
                    </Button>
          <ButtonCustom onClick={submitToggle} width='65px' height='42px' color='green'>Save</ButtonCustom>
        </div>
      </div>

    </Modal>
  )
}