import React, { memo, useEffect, useState } from "react"
import "./index.scss"
import { useHistory } from "react-router-dom"
import Logo from "../../static/images/logo.png"
import { Image } from "react-bootstrap"
import { getSidebarData } from "./SidebarData"
import { useHelpStorage } from "../../Providers/HelpProvider"
// import logo11exch from "../../static/11exch/11exch.png"
// import logofunexch from "../../static/funexch/funexch.svg"
// import logolotus365 from "../../static/lotus360/lotus365.png"
// import logouniconbet from "../../static/uniconbet/uniconbet.png"
// import logocheckingp from "../../static/checkingp/11exch.png"
const SideBar = () => {
  const [hostname, setHostname] = useState("");
  const history = useHistory()
  const { userLevel } = useHelpStorage()

  const followTheLink = path => () => {
    history.push(`/help/${userLevel}${path}`)

  }
  useEffect(() => {

    setHostname(window.location.hostname.split('.')[1])

  }, []
  )
  return (
    <div className="help__sidebar-menu">
      <div className="header-content__logo">
        <Image onClick={() => history.push("/")} src={"/assets/images/" + window.location.hostname.replace("www.", "").replace("app.", "").replace("support.", "") + "/logo.png"} />
      </div>
      <ul className="help-sidebar-menu__ul">
        {getSidebarData(userLevel).map(menuItem => (
          <li key={menuItem.title}>
            <a onClick={followTheLink(menuItem.path)}>{menuItem.title}</a>
            {menuItem.subMenu ? (
              <ul className="help-sidebar-menu__ul2">
                {menuItem.subMenu.map(subMenuItem => (
                  <li key={subMenuItem.title}>
                    <a onClick={followTheLink(subMenuItem.path)}>{subMenuItem.title}</a>
                    {subMenuItem.subMenu ? (
                      <ul className="help-sidebar-menu__ul3">
                        {subMenuItem.subMenu.map(childrenItem => (
                          <li key={childrenItem.title}>
                            <a onClick={followTheLink(childrenItem.path)}>{childrenItem.title}</a>
                          </li>
                        ))}
                      </ul>
                    ) : (
                      <></>
                    )}
                  </li>
                ))}
              </ul>
            ) : (
              <></>
            )}
          </li>
        ))}
      </ul>
    </div>
  )
}
export default memo(SideBar)
