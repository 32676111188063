import { useEffect, useState, Fragment } from "react"
import { OverlayTrigger, Table, Tooltip } from "react-bootstrap"
import ButtonCustom from "../../components/ButtonCustom"
import { useAuth } from "../../Providers/AuthProvider"
import "./index.scss"
import StringToRecolorNumber from "../../components/StringToRecolorNumber"
import { useStorage } from "../../Providers/StorageProvider"
import CurrentTime, { getTime } from "../../components/CurrentTime"
import { downloadCsv } from "../../utils/createCsv"
import RadioCustom from "../../components/RadioCustom"
import DatePicker from "../../components/DatePicker"
import jwt_decode from "jwt-decode"
import moment from "moment"
import axios from "axios"

export default ({ userId }) => {
  const [bonusData, setBonusData] = useState({})
  const [bonusSummaryData, setBonusSummaryData] = useState({})
  const { requestsToApi } = useAuth()
  const { userName, setIsLoading, activeTimeSetting } = useStorage()
  const [filter, setFilter] = useState("All")
  const endOfToday = moment().endOf("day")
  const startOfToday = moment().startOf("day")
  const [dateTimeRange, setDateTimeRange] = useState({ start: startOfToday, end: endOfToday })
  const [FROM, setFROM] = useState(new Date().toISOString().split("T")[0])
  const [TO, setTO] = useState(new Date().toISOString().split("T")[0])
  const [loading, setLoading] = useState(false)
  const [ReportType, setReportType] = useState("REWARD_SUMMARY_REPORT")
  const [ReportParam, setReportParam] = useState("BRANCH_ID")
  const [code, setCode] = useState(sessionStorage.getItem("USER_BRANCH_ID")?.toString())
  const getData = async () => {
    try {
      setIsLoading(true)
      const token = sessionStorage.getItem("accessToken")
      const axiosUrl = `http://10.255.254.18:5002/api/agency/report/bonus-report`
      const url = `/agency/report/bonus-report`
      const { master = 0 } = jwt_decode(token)
      // first 2 char only
      const sm_mc = master.toString().substring(0, 2) + "0101";
      const data = {
        REPORT_NAME: ReportType,
        SM_MC: sm_mc,
        FROM: new Date(FROM).toISOString().split("T")[0],
        TO: new Date(TO).toISOString().split("T")[0]
      }
      // const response = await axios.post(axiosUrl, data, { headers: { Authorization: `${token}` } })
      const response = await requestsToApi.post(url, data)
      console.log("response", response)
      if (!!response.data.status && !!response.data.result) {
        setBonusData(ReportType === "REWARD_REPORT" ? response.data.result : {})
        setBonusSummaryData(ReportType === "REWARD_SUMMARY_REPORT" ? response.data.result : {})
      }
      setIsLoading(false)
    } catch (e) {
      console.log("error", e)
      setIsLoading(false)
    }
  }
  useEffect(async () => {
    setFROM(new Date().toISOString().split("T")[0])
    setTO(new Date().toISOString().split("T")[0])
    // getData()
  }, [])
  const selectReportType = e => {
    // console.log(e.target.value)
    setReportType(e.target.value)
  }
  const selectReportParam = e => {
    // console.log(e.target.value)
    setReportParam(e.target.value)
  }
  return (
    <div className="transfer-statement-page">
      <div className="page-header">
        <div style={{ float: "left", display: "flex" }}>
          <h1>Bonus Statement</h1>
          <div className="bet-list-report__radios">
            {/* <RadioCustom name="category" status="REWARD_SUMMARY_REPORT" type="green" onChange={radioToggle} checked>
              Reword Summary Report
            </RadioCustom>
            <RadioCustom name="category" status="REWARD_REPORT" type="green" onChange={radioToggle}>
              Reword Report
            </RadioCustom> */}
          </div>
          <div className="row">
            <div className="col-md-3">
              <div className="form-group">
                Report Type
                <select className="form-control" onChange={selectReportType}>
                  <option value="REWARD_SUMMARY_REPORT" selected>
                    Summary Report :
                  </option>
                  <option value="REWARD_REPORT">Details Report</option>
                </select>
              </div>
            </div>
            <div className="col-md-3">
              <div className="form-group">
               From : <input
                  type="date"
                  className="form-control"
                  onChange={e => setFROM(new Date(e.target.value).getTime())}
                />
              </div>
            </div>
            <div className="col-md-3">
              <div className="form-group">
                To : <input type="date" className="form-control" onChange={e => setTO(new Date(e.target.value).getTime())} />
              </div>
            </div>
            <div className="col-md-3">
              <div className="form-group">
                Action : 
                <button className="btn btn-primary btn-block" onClick={getData}>
                  Get
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="transfer-statement-page__content">
        {/* transferData - last chenge */}
        {!!Object.keys(bonusSummaryData)?.length ? (
          <Table striped hover variant="dark">
            {Object.keys(bonusSummaryData).map((key, index) => {
              return (
                <tr>
                  <th>{key}</th>
                  <td>{JSON.stringify(bonusSummaryData[key])}</td>
                </tr>
              )
            })}
          </Table>
        ) : (
          <></>
        )}
        {bonusData.length ? (
          <Table striped hover variant="dark">
            <thead>
              <tr>
                <th>AMOUNT</th>
                <th>BONUS_ID</th>
                <th>LOGIN_NAME</th>
                <th>MEMBER_CODE</th>
                <th>TXN_DATE</th>
                <th>USR_ID</th>
              </tr>
            </thead>
            {bonusData.map((row, index) => {
              return (
                <tr>
                  <td>{row.AMOUNT}</td>
                  <td>{row.BONUS_ID}</td>
                  <td>{row.LOGIN_NAME}</td>
                  <td>{row.MEMBER_CODE}</td>
                  <td>{row.TXN_DATE}</td>
                  <td>{row.USR_ID}</td>
                </tr>
              )
            })}
          </Table>
        ) : (
          <></>
        )}
      </div>
    </div>
  )
}