import "./index.scss"
import { useEffect, useState } from "react"
import ButtonCustom from "../../components/ButtonCustom"
import DateTimeRangePicker from "../../components/DateTimeRangePicker"
import DropdownStatus from "../../components/DropdownStatus"
import PaginationCustom from "../../components/PaginationCustom"
// import SearchCustom from "../../components/SearchCustom";
import TextInputCustom from "../../components/TextInputCustom"
import moment from "moment"
import { useAuth } from "../../Providers/AuthProvider"
import { useLocation } from "react-router-dom"
import sound from "../../static/sound/26055615_alert_sound.mp3"
import axios from "axios"
import ReportsPopUp from "../../components/ReportsPopUp"

export default () => {
  let now = new Date()
  const endOfToday = moment().endOf("day")
  const startOfToday = moment().startOf("day")
  const type = useLocation().pathname.includes("deposit") ? "D" : "W"
  const { requestsToApi } = useAuth()
  const [reportsData, setReportsData] = useState([])
  const [newOptions, setNewOptions] = useState({
    utrInput: "",
    amountInput: "",
    status: "",
    userId: "",
    idInput: "",
    gatewayId: "",
    userName: "",
    managerId: "",
    date: null
  })
  const [popUpSettings, setPopUpSettings] = useState({
    action: type || "D",
    isVisible: false,
    data: null
  })
  const [lastSort, setLastSort] = useState({ createdAt: true })
  const [sortedReports, setSortedReports] = useState([])
  const [paginationReports, setPaginationReports] = useState([]);

  /// timer request states
  const [autoRefreshState, setAutoRefreshState] = useState(false)
  const [autoRefreshTimer, setAutoRefreshTimer] = useState(0)
  const [timerData, setTimerData] = useState(null)
  /// end timer request states
  const urlParams =
    "?" +
    "status=" + (newOptions.status ? newOptions.status[0] + newOptions.status.slice(1).toLowerCase() : "") +
    "&type=" + type +
    "&userId=" + newOptions.userId.toString() +
    "&amount=" + newOptions.amountInput.toString() +
    "&UTR=" + newOptions.utrInput.toString() +
    "&requestId=" + newOptions.idInput.toString() +
    "&gatewayId=" + newOptions.gatewayId.toString() +
    "&loginName=" + newOptions.userName.toString() +
    "&filterManagerId=" + newOptions.managerId.toString() +
    "&from=" + (!newOptions?.date?.start ? startOfToday : newOptions?.date?.start) +
    "&to=" + (!newOptions?.date?.end ? endOfToday : newOptions?.date?.end)

  const changeValue = (value, filterTitle) => {
    setNewOptions({ ...newOptions, [filterTitle]: value })
  }

  const handleClickClear = () => {
    setNewOptions({
      utrInput: "",
      amountInput: "",
      status: "",
      userId: "",
      idInput: "",
      gatewayId: "",
      userName: "",
      managerId: "",
      date: null
    })
    setReportsData([])
  }

  const handleClickApply = async () => {
    setSortedReports(null)
    try {
      // todo need API
      // const token = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzZXNzaW9uSWQiOm51bGwsImFjY2Vzc1JpZ2h0cyI6bnVsbCwiYWxsb3dTaGFrdGlQcm8iOmZhbHNlLCJleHAiOjE2NjQ5MDg0NDgsImlhdCI6MTY2NDE4ODQ0OCwiaXNNYXN0ZXIiOmZhbHNlLCJsYXN0QWNjZXNzUmlnaHRzVXBkYXRlRGF0ZSI6bnVsbCwibGFzdExvZ2luVGltZSI6MTY2NDE4MTc1MzAyMywibGV2ZWwiOiJCQU5LX01BTkFHRVIiLCJsb2dpbkFwcCI6bnVsbCwibG9naW5Db3VudHJ5IjpudWxsLCJsb2dpbklQIjpudWxsLCJsb2dpbk5hbWUiOiI3Nzc3NzAwMDAxIiwibWFzdGVyIjpudWxsLCJtYXN0ZXJJZCI6MCwibmJmIjowLCJzdGF0dXMiOiJBQ1RJVkUiLCJ0aGVtZSI6bnVsbCwiYXJlUnVsZXNBcHByb3ZlZCI6bnVsbCwicm9sZSI6bnVsbCwiaG9zdCI6bnVsbCwidG5jQWNjZXB0ZWQiOnRydWUsInVzZXIiOiJiYW5rIG1hbmFnZXIiLCJ1c2VySWQiOjE4LCJjdXJyZW5jeSI6bnVsbCwicGF5bWVudFN5c3RlbUlkIjoxLCJwYXltZW50U3lzdGVtTmFtZSI6IlRFU1RfUFMiLCJwYXltZW50U3lzdGVtU0EiOlsiVDEiXX0.5PB9qNpyDhppJ2D0weqgEh8nfdaeH156_oXq--XOViQ"
      // const response = await axios.get(`https://192.168.1.90:5002/api/banking/dw-report${urlParams}`, { headers: { "Authorization": `${token}` } })
      const response = await requestsToApi.get("/banking/dw-report" + urlParams);
      setSortedReports(response.data.result.sort((a, b) => {if (a.createdAt > b.createdAt) return -1;}));
      setReportsData(response.data.result || [])
    } catch (error) {
      console.log(error?.message)
    }
  }

  useEffect(() => {
    handleClickClear()
  }, [type])

  // useEffect(() => {
  //   setDateForPagination(newOptions?.date?.start || new Date())
  //   setFirstDay(new Date(newOptions?.date?.start))
  // }, [newOptions.date])

  const showDepositPopUpPopup = (report) => {
    setPopUpSettings({ ...popUpSettings, isVisible: true, data: report })
  }
  const changeSort = (key) => {
    setLastSort(lastSort[key] ? { [key]: !lastSort[key] } : { [key]: true })
  }

  useEffect(() => {
    let dataForSort = (autoRefreshState && timerData) ? [...timerData] : [...reportsData]

    if ((dataForSort && dataForSort.length >= 2) || (autoRefreshState && dataForSort)) {
      const key = Object.keys(lastSort)[0]

      if (key === "amount") {
        !lastSort[key] ? setSortedReports([...dataForSort?.sort((a, b) => a[key] - b[key])]) : setSortedReports([...dataForSort?.sort((a, b) => b[key] - a[key])])
      }
      if (key !== "amount") {
        const filter = dataForSort.filter(item => item[key])
        const sort = filter.sort((a, b) => {
          if (a[key] > b[key]) return -1
        })
        setSortedReports(!lastSort[key] ? [...sort.reverse(), ...dataForSort.filter(item => !item[key])] : [...sort, ...dataForSort.filter(item => !item[key])])
      }
    }
  }, [lastSort, reportsData, timerData])

  // TIMER REQUEST
  let timerRequest = async () => {
    setSortedReports(null)
    try {
      // const token = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzZXNzaW9uSWQiOm51bGwsImFjY2Vzc1JpZ2h0cyI6bnVsbCwiYWxsb3dTaGFrdGlQcm8iOmZhbHNlLCJleHAiOjE2NjQ5MDg0NDgsImlhdCI6MTY2NDE4ODQ0OCwiaXNNYXN0ZXIiOmZhbHNlLCJsYXN0QWNjZXNzUmlnaHRzVXBkYXRlRGF0ZSI6bnVsbCwibGFzdExvZ2luVGltZSI6MTY2NDE4MTc1MzAyMywibGV2ZWwiOiJCQU5LX01BTkFHRVIiLCJsb2dpbkFwcCI6bnVsbCwibG9naW5Db3VudHJ5IjpudWxsLCJsb2dpbklQIjpudWxsLCJsb2dpbk5hbWUiOiI3Nzc3NzAwMDAxIiwibWFzdGVyIjpudWxsLCJtYXN0ZXJJZCI6MCwibmJmIjowLCJzdGF0dXMiOiJBQ1RJVkUiLCJ0aGVtZSI6bnVsbCwiYXJlUnVsZXNBcHByb3ZlZCI6bnVsbCwicm9sZSI6bnVsbCwiaG9zdCI6bnVsbCwidG5jQWNjZXB0ZWQiOnRydWUsInVzZXIiOiJiYW5rIG1hbmFnZXIiLCJ1c2VySWQiOjE4LCJjdXJyZW5jeSI6bnVsbCwicGF5bWVudFN5c3RlbUlkIjoxLCJwYXltZW50U3lzdGVtTmFtZSI6IlRFU1RfUFMiLCJwYXltZW50U3lzdGVtU0EiOlsiVDEiXX0.5PB9qNpyDhppJ2D0weqgEh8nfdaeH156_oXq--XOViQ"
      // let timerResponse = await axios.get(`https://192.168.1.90:5002/api/banking/dw-report${urlParams}`, { headers: { "Authorization": `${token}` } })
      let timerResponse = await requestsToApi.get("/banking/dw-report" + urlParams)
      timerResponse = timerResponse.data.result

      let filteredByDateData = timerResponse.filter(item => {
        let todaysDate = new Date()
        let itemNewDate = new Date(item.createdAt)
        if ((itemNewDate.getFullYear() === todaysDate.getFullYear()) &&
          (itemNewDate.getMonth() === todaysDate.getMonth()) &&
          (itemNewDate.getDate() === todaysDate.getDate())) {
          return item
        } else return null
      })
      if (timerData && filteredByDateData && (filteredByDateData.length !== timerData.length)) {
        setTimerData(filteredByDateData)
        new Audio(sound).play()
        setLastSort({ "createdAt": true })
      } else {
        filteredByDateData.length > 0 ? setTimerData(filteredByDateData) : setTimerData(null)
      }
      setAutoRefreshTimer(intervalTime)
    } catch (error) {
      console.log(error?.message)
      setAutoRefreshTimer(intervalTime)
    }
  }
  // END TIMER REQUEST

  // // TIMER
  let interval
  let intervalTime = 30

  useEffect(() => {
    if (autoRefreshState) {
      setAutoRefreshTimer(intervalTime)
      setLastSort({ "createdAt": true })
    }
    if (!autoRefreshState) {
      setAutoRefreshTimer(0)
      return () => clearInterval(interval)
    }
  }, [autoRefreshState])

  useEffect(() => {
    if (!autoRefreshState) {
      return () => clearInterval(interval)
    }
    if (autoRefreshTimer === 0) {
      setNewOptions({...newOptions, date: null})
      timerRequest()
    }
    if (autoRefreshState && autoRefreshTimer !== 0) {
      interval = setInterval(() => {
        setAutoRefreshTimer(autoRefreshTimer - 1)
        // console.log("autoRefreshTimer", autoRefreshTimer)
      }, 1000)
    }
    return () => clearInterval(interval)
  }, [autoRefreshTimer])
  // // END TIMER

  //PAGINATION
  const [activePage, setActivePage] = useState(1);
  const [allPages, setAllPages] = useState(1);
  const reportsByPage = 100;
  useEffect(() => {
    if (sortedReports) {
      const reports = [];
      for (let i = 0; i <Math.ceil(sortedReports.length/reportsByPage); i++){
        reports[i] = sortedReports.slice((i*reportsByPage), (i*reportsByPage) + reportsByPage);
      }
      setAllPages(reports.length || 1)
      setPaginationReports(reports)
    }
  }, [sortedReports]);
  // END PAGINATION

  return (
    <div className="customer-reports">
      {popUpSettings?.isVisible && <ReportsPopUp
        visible={popUpSettings.isVisible}
        closeFunc={() => setPopUpSettings({ ...popUpSettings, isVisible: false })}
        reloadFunc={handleClickApply}
        data={popUpSettings.data}
        action={popUpSettings.action}
      />}
      <div className="customer-reports__header">
        <div className="customer-reports__header_date-rows">
          <div className="customer-reports__header_date-row">
            <h1> Customer {type === "D" ? "Deposits" : "Withdrawals"}</h1>
            <DateTimeRangePicker dateTimeRange={autoRefreshState ? false : newOptions.date}
                                 onChangeFunc={(value) => changeValue(value, "date")} />
          </div>
          <div className="customer-reports__header_date-unput-row">
            <TextInputCustom className="customer-reports__textInputCustom"
                             onChange={(value) => changeValue(value, "utrInput")} placeholder="UTR">
              {newOptions.utrInput}
            </TextInputCustom>
            <TextInputCustom className="customer-reports__textInputCustom"
                             onChange={(value) => changeValue(value, "amountInput")} placeholder="Amount">
              {newOptions.amountInput}
            </TextInputCustom>
            <div className="customer-reports__header_date-input-row-drop-down-wrapper">
              <DropdownStatus
                options={["Pending", "Complete", "Cancelled"]}
                title={newOptions.status}
                onChange={(value) => changeValue(value, "status")} />
            </div>
            <TextInputCustom className="customer-reports__textInputCustom"
                             onChange={(value) => changeValue(value, "userId")} placeholder="User ID">
              {newOptions.userId}
            </TextInputCustom>
            <TextInputCustom className="customer-reports__textInputCustom"
                             onChange={(value) => changeValue(value, "idInput")} placeholder="Request ID">
              {newOptions.idInput}
            </TextInputCustom>
            <TextInputCustom className="customer-reports__textInputCustom"
                             onChange={(value) => changeValue(value, "gatewayId")} placeholder="Gateway Id">
              {newOptions.gatewayId}
            </TextInputCustom>
            <TextInputCustom className="customer-reports__textInputCustom"
                             onChange={(value) => changeValue(value, "userName")} placeholder="User Name">
              {newOptions.userName}
            </TextInputCustom>
            <TextInputCustom className="customer-reports__textInputCustom"
                             onChange={(value) => changeValue(value, "managerId")} placeholder="Manager ID">
              {newOptions.managerId}
            </TextInputCustom>
          </div>
        </div>
        <div className="customer-reports__header-search-column">
          <div className={`customer-reports__auto-refresh-wrapper`}>
            <p>Auto-Refresh</p>
            <div className={`customer-reports__auto-refresh-button-wrapper`}>
              <span className={`customer-reports__auto-refresh-timer`}>{autoRefreshTimer}</span>
              <div className={`customer-reports__auto-refresh-button ${(autoRefreshState === true) ? "active" : ""}`}
                   onClick={() => setAutoRefreshState(prevState => !prevState)}>
                <span className={`customer-reports__auto-refresh-toggle-icon`}></span>
                <span className={`customer-reports__auto-refresh-on`}>ON</span>
                <span className={`customer-reports__auto-refresh-off`}>OFF</span>
              </div>
            </div>
          </div>
          <div className="customer-reports__header-search-column-buttons">
            <ButtonCustom onClick={() => {
              handleClickApply()
              setAutoRefreshState(false)
            }} color="cyan">Apply</ButtonCustom>
            <ButtonCustom onClick={handleClickClear} color="red">Clear</ButtonCustom>
          </div>
        </div>
      </div>
      <div className="customer-reports__table">
        <div className="customer-reports__table-row-date">
          <div className="customer-reports__table-paginaton">
            <PaginationCustom
              totalPages={allPages}
              activePage={activePage}
              changeActivePage={page => setActivePage(page)}
            />
          </div>
        </div>

        <ul className={`customer-reports__table-header ${autoRefreshState ? "autoRefreshOn" : ""}`}>
          <li onClick={() => changeSort("createdAt")}
              className={`customer-reports__table-header-sort-button customer-reports__createdAt-button`}>
            <span className={`customer-reports__table-header-sort ${lastSort.createdAt ? "" : "up"}`}>CreatedAt</span>
          </li>
          <li onClick={() => changeSort("updatedAt")} className={`customer-reports__table-header-sort-button`}>
            <span className={`customer-reports__table-header-sort ${lastSort.updatedAt ? "" : "up"}`}>CompletedAt</span>
          </li>
          <li className={`customer-reports__table-header-sort-button`}>Request ID</li>
          <li className={`customer-reports__table-header-sort-button`}>Username</li>
          <li onClick={() => changeSort("amount")} className={`customer-reports__table-header-sort-button`}>
            <span className={`customer-reports__table-header-sort ${lastSort.amount ? "" : "up"}`}>Amount</span>
          </li>
          <li className={`customer-reports__table-header-sort-button`}>UTR_NO</li>
          <li className={`customer-reports__table-header-sort-button`}>Status</li>
          <li className={`customer-reports__table-header-sort-button`}>Bank manager</li>
          <li className={`customer-reports__table-header-sort-button`}>Gateway</li>
          <li className={`customer-reports__table-header-sort-button`}>IP</li>
          <li className={`customer-reports__table-header-sort-button`}>Action</li>
        </ul>
        <div className="customer-reports__table-body">
          {sortedReports && paginationReports && paginationReports[activePage - 1]?.map((report, index) =>
            <ul key={report.requestId + index}
                className="customer-reports__table-body-row">
              <li className="customer-reports__table-body-row-date">
                <div>{moment(report?.createdAt)?.format("DD.MM.YY")}</div>
                <div className="time">{moment(report?.createdAt)?.format("LTS")}</div>
              </li>
              <li className="customer-reports__table-body-row-date">
                {report.updatedAt &&
                  <div>
                    <div>{moment(report.updatedAt)?.format("DD.MM.YY")}</div>
                    <div className="time">{moment(report.updatedAt)?.format("LTS")}</div>
                  </div>}
              </li>
              <li>{report?.requestId}</li>
              <li>{report?.loginName}</li>
              <li>{report?.amount}</li>
              <li>{report?.UTR}</li>
              <li>{report?.status}</li>
              <li>{report?.manager}</li>
              <li className="customer-reports__table-body-row-date">
                {report.gateway &&
                  <div>
                    <div>{report.gateway?.name}</div>
                    <div>{report.gateway?.acNo}</div>
                    <div>{report.gateway?.bankName}</div>
                  </div>}
              </li>
              <li>{report?.IP}</li>
              <li className={`customer-reports__table-body-row-cancel ${report?.status}`}>
                <ButtonCustom onClick={() => showDepositPopUpPopup(report)} color="red">X</ButtonCustom>
              </li>
            </ul>
          )}
        </div>
          <div className="customer-reports__table-row-date">
              <div className="customer-reports__table-paginaton">
                <PaginationCustom
                  totalPages={allPages}
                  activePage={activePage}
                  changeActivePage={page => setActivePage(page)}
                />
              </div>
          </div>
      </div>
    </div>
  )
};
