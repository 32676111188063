import "./CardsTeenpattiPL.scss";
import React, { useEffect, useState } from 'react'

const CardsTeenpattiPL = ({roundMarkets}) => {
 let [winnerData, setWinnerData] = useState(null);

 const getWinner = () => {
   let winObj = {
   };
   if (roundMarkets.length > 0) {
     roundMarkets.forEach((market) => {
       market.marketRunner.forEach((runner) => {
         if (runner.status === 'WINNER') {
           winObj[market.marketHeader] = runner.name;
         }
       });
     });
   }
   setWinnerData(winObj)
  }
  useEffect(() => {
    getWinner()
  }, [roundMarkets]);

  const getTotalA = () => {
    const totalA = roundMarkets[2]?.marketRunner[0].total;
    return totalA;
  }

  const getTotalB = () => {
    const totalB = roundMarkets[3]?.marketRunner[0].total;
    return totalB;
  }

  const getMiniBaccaratA = () => {
    const miniBaccaratA = roundMarkets[1]?.marketRunner[0].total;
    return miniBaccaratA;
  }

  const getMiniBaccaratB = () => {
    const miniBaccaratB = roundMarkets[1]?.marketRunner[1].total;
    return miniBaccaratB;
  }

  const cardSrc = (card) => {
    if (card) {
      return `https://stream.fawk.app/assets/smallCards/${card}.png`;
    } else {
      return "https://stream.fawk.app/assets/smallCards/BACK.png";
    }
  };

    return (
      <div className="cards-teenpatti-betting">
        <div className="cards-teenpatti-betting__games">
          <div className="cards-teenpatti-betting__games-item">
            <span
              className={`cards-teenpatti-betting__games-item-title ${roundMarkets[0].marketRunner[0].status === 'WINNER' ? 'winnerCup':''}`}>
              Player A
            </span>
            <div className="cards-teenpatti-betting__games-item-cards">
              {roundMarkets && roundMarkets[0].marketRunner[0]?.cards.map( card =>
                <img className="cards-teenpatti-betting__games-item-cards-card"
                     key={card}
                     src={cardSrc(card)}
                     alt={card}
                />
              )}
            </div>
          </div>
          <div className="cards-teenpatti-betting__games-item">
            <span
              className={`cards-teenpatti-betting__games-item-title second ${roundMarkets[0].marketRunner[1].status === 'WINNER' ? 'winnerCup':''}`}>
                Player B
            </span>
            <div className="cards-teenpatti-betting__games-item-cards">
              {roundMarkets && roundMarkets[0].marketRunner[1]?.cards.map( card =>
                <img className="cards-teenpatti-betting__games-item-cards-card"
                     key={card}
                     src={cardSrc(card)}
                     alt={card}
                />
              )}
            </div>
          </div>
        </div>
        {winnerData && <div className="cards-teenpatti-betting__info-column">
          <div className="cards-teenpatti-betting__info-row">
            <div className="cards-teenpatti-betting__info-row-title">
              <span>Winner: </span>
            </div>
            <div className="cards-teenpatti-betting__info-row-results">
              <span>{winnerData['Match Odds'] || 'No Winner'}</span>
            </div>
          </div>
          <div className="cards-teenpatti-betting__info-row">
            <div className="cards-teenpatti-betting__info-row-title">
              <span>Mini Baccarat: </span>
            </div>
            <div className="cards-teenpatti-betting__info-row-results">
              <span>{winnerData['Mini Baccarat']} ( A: {getMiniBaccaratA()} | B: {getMiniBaccaratB()})</span>
            </div>
          </div>
          <div className="cards-teenpatti-betting__info-row">
            <div className="cards-teenpatti-betting__info-row-title">
              <span>Total: </span>
            </div>
            <div className="cards-teenpatti-betting__info-row-results">
              <span className="cards-teenpatti-betting__info-row-results">
                A: {getTotalA()} | B: {getTotalB()}
              </span>
            </div>
          </div>
          <div className="cards-teenpatti-betting__info-row">
            <div className="cards-teenpatti-betting__info-row-title">
              <span>Color Plus: </span>
            </div>
            <div className="cards-teenpatti-betting__info-row-results">
              <span className="cards-teenpatti-betting__info-row-results">
               {winnerData['Color Plus'] === 'Color Plus' ? 'Yes': 'No' }
              </span>
            </div>
          </div>
        </div>}
      </div>

    );
};

export default CardsTeenpattiPL;

