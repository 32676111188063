import React, { useState, useEffect, useRef } from "react"
import { useHistory } from "react-router-dom"
import ButtonCustom from "../../components/ButtonCustom"
import TextInputCustom from "../../components/TextInputCustom"
import { useAuth } from "../../Providers/AuthProvider"
import "./index.scss"

// import logo11exch from "../../static/11exch/11exch.png"
// import logofunexch from "../../static/funexch/funexch.svg"
// import logolotus365 from "../../static/lotus360/lotus365.png"
// import logouniconbet from "../../static/uniconbet/uniconbet.png"
// import logocheckingp from "../../static/checkingp/11exch.png"

const Login = () => {
  // const [inputUserValue, setInputUserValue] = useState('hkdsa');
  // const [inputPassValue, setInputPassValue] = useState('abcd1234');
  const [inputUserValue, setInputUserValue] = useState("")
  const [inputPassValue, setInputPassValue] = useState("")
  const [message, setMessage] = useState("")
  const { requestsToApi, auth, isAuthenticated } = useAuth()
  const history = useHistory()
  const passInput = useRef(null)
  const submitButton = useRef(null)
  const [hostname, setHostname] = useState("");

  useEffect(() => {
    setHostname(window.location.hostname.split('.')[1])
    if (isAuthenticated) history.push("/account/balance")
  }, [])

  const validateFields = e => {
    e.preventDefault()
    if (inputUserValue.trim().length && inputPassValue.trim().length) {
      login()
    } else {
      if (!inputUserValue && !!inputPassValue) {
        setMessage("Username is required.")
      } else {
        if (!inputPassValue & !!inputUserValue) {
          setMessage("Password is required.")
        } else {
          setMessage("All fields must be filled in")
        }
      }
    }
  }

  //it's for agensy

  const login = async () => {
    try {
      const response = await requestsToApi.post("/auth/login", {
        password: inputPassValue,
        username: inputUserValue
      })
      if (response.headers.authorization) {
        sessionStorage.setItem("myUserName", response.data.result.user.name)
        sessionStorage.setItem("myLoginName", response.data.result.user.loginName)
        sessionStorage.setItem("myLastLoginTime", response.data.result.user.lastLoginTime)
        sessionStorage.setItem("myLevel", response.data.result.user.level)
        sessionStorage.setItem("isFranchiseMaster", response.data.result.user.isFranchiseMaster)
        sessionStorage.setItem("role", response.data.result.user.role)
        sessionStorage.setItem("paymentSystemId", response.data.result.user.paymentSystemId)
        auth(response.headers.authorization)
      }
    } catch (error) {
      console.log("error", error)
      if (error?.status === 401) {
        setMessage("Wrong email or password")
      } else {
        setMessage(error?.message)
      }
    }
  }

  //it's for payments

  // const login = async () => {
  //   try {
  //     const response = await requestsToApi.post('/appusers/auth/login', {
  //       mobile: inputUserValue,
  //       password: inputPassValue
  //     })
  //     if (response.headers.authorization) {
  //       sessionStorage.setItem('myUserName', response.data.result.user.name)
  //       sessionStorage.setItem('myLoginName', response.data.result.user.loginName)
  //       sessionStorage.setItem('myLastLoginTime', response.data.result.user.lastLoginTime)
  //       sessionStorage.setItem('myLevel', response.data.result.user.level)
  //       auth(response.headers.authorization)
  //     }
  //   } catch (error) {
  //     console.log("error", error)
  //     if (error?.status === 401) {
  //       setMessage("Wrong email or password")
  //     } else {
  //       setMessage(error?.message)
  //     }
  //   }
  return (
    <div className="login-page">
      <form method="POST" onSubmit={validateFields}>
        <div className="login-block">
          <img
            src={"/assets/images/" + window.location.hostname.replace("www.", "").replace("app.", "").replace("support.", "") + "/logo.png"}
            alt=""
            style={{ width: "100%" }}
          />

          <TextInputCustom
            label="Enter Username:"
            type="text"
            color="white"
            onChange={setInputUserValue}
            onEnter={() => passInput.current.focus()}
          >
            {inputUserValue}
          </TextInputCustom>
          <TextInputCustom
            label="Enter Password:"
            type="password"
            color="white"
            ref={passInput}
            onChange={setInputPassValue}
            onEnter={() => submitButton.current.focus()}
            autocomplete="new-password"
          >
            {inputPassValue}
          </TextInputCustom>
          <ButtonCustom
            className="login__button"
            color="purple"
            // height="42px"
            ref={submitButton}
          >
            LOGIN
          </ButtonCustom>
          <p>Best Viewed In:</p>
          <div className="login__icons">
            <div className="firefox-icon"></div>
            <div className="ie-icon"></div>
            <div className="chrome-icon"></div>
          </div>
          {message ? <p className="login__message">{message}</p> : <></>}
        </div>
      </form>
    </div>
  )
}

export default Login
