import './index.scss'
import ButtonCustom from '../../components/ButtonCustom'
import ArrowRightIcon from 'mdi-react/ArrowRightThickIcon'
import { useEffect, useState } from 'react'
// import ModalAcceptLock from '../../components/ModalAcceptLock'
import { useHistory } from 'react-router-dom'
import { useAuth } from '../../Providers/AuthProvider'
import Nav from './Nav'
import MATable from './MATable'
import { useStorage } from '../../Providers/StorageProvider';
import SearchUserInput from '../../components/SearchUserInput'
import { downloadCsv } from '../../utils/createCsv'
import PaginationCustom from '../../components/PaginationCustom'
import InputCustom from '../../components/TextInputCustom'

export default () => {

  const [isShowAllTable, setIsShowAllTable] = useState(false);
  const [downlineData, setDownlineData] = useState([]);
  const [uplineData, setUplineData] = useState([]);
  const [showDWButtons,setShowDWButtons] = useState(false);
  const [isActionButtonActive,setIsActionButtonActive] = useState(false);
  const [filterData, setFilterData] = useState({downlineData: null, uplineData: null});
  const history = useHistory();
  const { requestsToApi } = useAuth();
  const [getReqTrigger, setGetReqTrigger] = useState(false);
  const { userName, childrenLevel, setIsLoading, userLevel } = useStorage();
  const [searchUserUrl, setSearchUserUrl] = useState([]);
  const [showOption, setShowOption] = useState(false)
  const [selectedStatus, setSelectedStatus] = useState("Status")
  const [pageData,setpageData] = useState();
  const [activePageNumber, setActivePageNumber] = useState(1)

  useEffect(() => {
    // console.log("first")
    if (selectedStatus !== "Status") {
      setFilterData({
        downlineData: downlineData.filter(item => item?.user?.status === selectedStatus.toUpperCase()),
        uplineData: uplineData.filter(item => item?.user?.status === selectedStatus.toUpperCase())
      })
    }
  }, [selectedStatus])

  useEffect(() => {
    localStorage.removeItem("lastSearch")
  }, []);

  let splitUrl = history.location.pathname.split('/');
  if (splitUrl.slice(-1)[0] === "") splitUrl.pop()
  let triggerUSers = true

  const downlineUsersFromURLReq = async () => {
    try {
      if (triggerUSers) {
        triggerUSers = false
        setIsLoading(true)
        setDownlineData([])
        const parent = splitUrl.slice(-1)[0] === "downline" ? userName :
          splitUrl.slice(-1)[0];
        const isFindedSearchCode = history.location.search.split("&").find(value => /searchCode=/.test(value));
        const searchCode = isFindedSearchCode ? isFindedSearchCode.split("=")[1] : null;
        const response = await requestsToApi.get(`agency/${userName}/agency-mgmt/downline?parent=${userName}&pageNumber=${activePageNumber}${searchCode ? '&searchUser=' + searchCode : ''}`);
        setDownlineData(response.data.result.downline)
        setUplineData(response.data.result.upline);
        setShowDWButtons(response.data.result.showDWButtons);
        setpageData({
          pageNumber: response.data.result.pageNumber,
          totalPages: response.data.result.totalPages})
        setIsActionButtonActive(response.data.result.isActionButtonActive);
        setIsLoading(false)
      }
    } catch (error) {
      console.log(error?.message)
      setIsLoading(false)
    }
    finally {
      triggerUSers = true
    }
  }

  const handleOptionClick = e => {
    setSelectedStatus(e.target.innerHTML)
    setShowOption(false)
  }

  const balanceTextController = (balance, field) => {
    if (!balance) return ""
    else if (balance > 0) {
      if (field === "take") return ""
      else if (field === "give") {
        return balance
      }
    } else {
      if (field === "take") {
        return balance
      } else if (field === "give") return ""
    }
  }

  const getDataForCSV = () => {
    let data = downlineData.map(item => ({
      "Login Name": item.user.loginName,
      "ID": item.user.name,
      "Net Exposure": item.account.actualNetExposure,
      "Take": balanceTextController(item.account.settledBalance, "take"),
      "Give": balanceTextController(item.account.settledBalance, "give"),
      "Credit Limit": item.account.creditLimit,
      "Available Credit": userLevel !== 'Agent' ? item.account.availableBalance : '',
      "PT(%)C/F/T/H/G/X": `${item.user.pt.CRICKET}/${item.user.pt.EXCHANGE_GAMES}/${item.user.pt.FOOTBALL}/${item.user.pt.GREYHOUND_RACING}/${item.user.pt.HORSE_RACING}/${item.user.pt.TENNIS}`,
      "Created": item.user["createdDate"],
      "Last Login": item.user["lastLoginTime"],
      "Last IP": item.user.lastLoginIp
    }))
    // console.log('data', data)
    return { data: data }

  }

  useEffect(() => {
    const parent = splitUrl.slice(-1)[0] === "downline" ? userName : splitUrl.slice(-1)[0]
    console.log("55555", history.location.pathname.split("/"))
    if (history.location.pathname.split("/").length === 4 && history.location.pathname.split("/")[3].length === 6) {
      if (parent.indexOf("0101") === -1) {
        downlineUsersFromURLReq()
      } else {
        setDownlineData([])
        setUplineData([])
        setShowDWButtons(false)
        setpageData([])
        setIsActionButtonActive(false)
        setIsLoading(false)
        return
      }
    }
    if (parent.length === 6) {
      if (parent.indexOf("0101") === -1) {
          downlineUsersFromURLReq()
      } else if (history.location.pathname.indexOf("0101") !== -1) {
        downlineUsersFromURLReq()
      }
    } else {
      if(childrenLevel !== 'SMA'){
        downlineUsersFromURLReq()
      }
    }
  }, [history.location.pathname, activePageNumber])

  useEffect(() => {
    if (getReqTrigger) {
      downlineUsersFromURLReq()
      if (getReqTrigger) setGetReqTrigger(false);
    }
  }, [getReqTrigger])

  const addClassWhileShowAllTable = () => isShowAllTable ? "show-all-table" : "show-part-of-table";

  const [searchUserValue, setSearchUserValue] = useState('');


  const userSearch = async () => {
      // setSearchValue(value)
      // if (value.length >= 4) {
      const response = await requestsToApi.get('agency/agency-mgmt/search-downline?isMemberOnly=false&searchQuery=' + searchUserValue)
        // setSearchResult(response.data.result)
        console.log('response.data.result', response.data.result);
      // }
    if (response.data.result.downline[0]) {
        if (history.location.pathname !== '/agency-management/downline') {
          history.push('/agency-management/downline')
        }
      console.log(response.data.result.downline?.[0],"user");
      setSearchUserUrl(`/agency-management/downline/${response.data.result.downline?.[0].user.hirarchy}/?searchCode=${response.data.result.downline?.[0].user.name}`)
      history.push(`/agency-management/downline/${response.data.result.downline?.[0].user.hirarchy}/?searchCode=${response.data.result.downline?.[0].user.name}`)
        setGetReqTrigger(true);
      localStorage.setItem("lastSearch", `/agency-management/downline/${response.data.result.downline?.[0].user.hirarchy}/?searchCode=${response.data.result.downline?.[0].user.name}`)
      }
      console.log(response.data.result.downline?.[0],"user");
      setSearchUserUrl(`/agency-management/downline/${response.data.result.downline?.[0].user.hirarchy}/?searchCode=${response.data.result.downline?.[0].user.name}`)
      history.push(`/agency-management/downline/${response.data.result.downline?.[0].user.hirarchy}/?searchCode=${response.data.result.downline?.[0].user.name}`)
      setGetReqTrigger(true);
      localStorage.setItem("lastSearch", `/agency-management/downline/${response.data.result.downline?.[0].user.hirarchy}/?searchCode=${response.data.result.downline?.[0].user.name}`)
  }


  const pagination = (
    <PaginationCustom
      activePage={activePageNumber}
      changeActivePage={setActivePageNumber}
      totalPages={pageData?.totalPages}
    />
  )
  return (
    <div className="children-listing">
      <div className="children-listing__header page-header">
        <div className="children-listing__title">
          <h1>{childrenLevel} Listing</h1>
        </div>
        <div className="children-listing__search">
          <p>Search by Login Name</p>
          <input autocomplete='false' name='searchUser' type='text' id='3222' autoCorrect='false' onChange={(e) => setSearchUserValue(e.target.value)}/>
          <ButtonCustom color="cyan" onClick={() => userSearch() } >Search</ButtonCustom>
          {/* <SearchUserInput
            placeholder="Agency/Member"
            dropdownClickFunc={(user) => {
              if (history.location.pathname !== '/agency-management/downline') {
                history.push('/agency-management/downline')
              }
              console.log(user,"user");
              setSearchUserUrl(`/agency-management/downline/${user.parentCode}/?searchCode=${user.userCode}`)
              history.push(`/agency-management/downline/${user.parentCode}/?searchCode=${user.userCode}`)
              setGetReqTrigger(true);
              localStorage.setItem("lastSearch", `/agency-management/downline/${user.parentCode}/?searchCode=${user.userCode}`)
            }}
            defaultInputValue=""
            type="none"
          /> */}
        </div>
        <div className="children-listing__status-filter">
          <div
            className={showOption ? "children-listing__status-filter-selected active" : "children-listing__status-filter-selected"}
            onClick={() => setShowOption(prevState => !prevState)}>
            {selectedStatus}
          </div>
            <ul className={showOption ? "children-listing__status-filter-options active" : "children-listing__status-filter-options"}>
              <li className="children-listing__status-filter-option" onClick={handleOptionClick}>Active</li>
              <li className="children-listing__status-filter-option" onClick={handleOptionClick}>Inactive</li>
              <li className="children-listing__status-filter-option" onClick={handleOptionClick}>Suspended</li>
              <li className="children-listing__status-filter-option" onClick={handleOptionClick}>Closed</li>
            </ul>
        </div>
        <div style={{ width: "7rem" }}></div>
        <div className="children-listing__buttons">
          <ButtonCustom color="cyan" onClick={() => downloadCsv(getDataForCSV())}>Download CSV</ButtonCustom>
          {splitUrl.slice(-1)[0] === "downline" ?
            <ButtonCustom onClick={() => history.push('/agency-management/downline/create/MA')}>
              New {childrenLevel}
            </ButtonCustom> : <></>}
        </div>
      </div>
      <div className="children-listing__main">
        <div className="nav">
          <Nav uplineData={uplineData} url={history.location.pathname} />
        </div>
          {pageData ? (
          <>
            {pagination}
          </>
        ) : (
          <></>
        )}
        <div className="content">
          <div className="table-conteiner">
            <MATable
              downlineData={filterData.downlineData || downlineData}
              uplineData={filterData.uplineData || uplineData}
              className="children-listing-table left-part"
              addClassWhileShowAllTable={addClassWhileShowAllTable}
              splitUrl={splitUrl}
              setGetReqTrigger={setGetReqTrigger}
              searchUserUrl={searchUserUrl}
              showDWButtons={showDWButtons}
              isActionButtonActive={isActionButtonActive}
            />

          </div>
          <div className="children-listing__toggle-table-show" onClick={() => setIsShowAllTable(!isShowAllTable)}>
            <ArrowRightIcon className={addClassWhileShowAllTable()} />
            <ArrowRightIcon className={addClassWhileShowAllTable()} />
          </div>
        </div>
      </div>
    </div>
  )
}
