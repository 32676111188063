import "./index.scss"
import RadioCustom from "../../../components/RadioCustom"
import { Table } from "react-bootstrap"
import { useEffect, useState } from "react"
import { numberWithCommas } from "../../../utils/dataTransform"
import { useAuth } from "../../../Providers/AuthProvider"
import StringToRecolorNumber from "../../../components/StringToRecolorNumber"
import { useStorage } from "../../../Providers/StorageProvider"

export default ({ userId, userLevel, getWallets }) => {
  const { requestsToApi } = useAuth()
  const [responseData, setResponseData] = useState(null)
  const [displayedData, setDisplayedData] = useState(null)


  useEffect(async () => {
    try {
      const responce = await requestsToApi.get(`agency/report/bonuses?memberCode=${userId}&bonusType=all`)
      setResponseData(responce.data.result)
      setDisplayedData(responce.data.result)
    } catch (error) {
      console.log(error?.message)
    }
  }, [userId])

  const radioToggle = e => {
    console.log(responseData,e.target.attributes["status"].value)
    if (Number(e.target.attributes["status"].value) == 0) setDisplayedData(responseData);
    else setDisplayedData(responseData.filter(item => item.STS_ID == e.target.attributes["status"].value));
    console.log(responseData);
  }

  const formatData = (num, recolor) => {
    return recolor ? <StringToRecolorNumber>{num}</StringToRecolorNumber> : <span>{numberWithCommas(num)}</span>
  }

  return (
    <div className="bonuses-report">
      <div className="bonuses-report__controls">
        <RadioCustom status="0" onChange={radioToggle} checked>
          All
        </RadioCustom>
        <RadioCustom status="10" onChange={radioToggle}>
          Pending
        </RadioCustom>
        <RadioCustom status="20" onChange={radioToggle}>
          Active
        </RadioCustom>
        <RadioCustom status="30" onChange={radioToggle}>
          Expired
        </RadioCustom>
      </div>
      <div className="bonuses-body">
        <Table striped hover variant="dark">
          <thead>
            <tr>
              <th scope="col">ACTIVATION_DATE</th>
              <th scope="col">AMOUNT</th>
              <th scope="col">Total Wagered </th>
              <th scope="col">BONUS_ID</th>
              <th scope="col">COMMENT</th>
              <th scope="col">EXPIRY_DATE</th>
              <th scope="col">ISSUE_DATE</th>
              <th scope="col">PROMO_CODE</th>
              <th scope="col">REWARD_DATE</th>
              <th scope="col">STS_ID</th>
            </tr>
          </thead>
          <tbody>
            {displayedData ? (
              displayedData.map((item, index) => {
                return (
                  <tr key={index}>
                    <td>{item.ACTIVATION_DATE ? item.ACTIVATION_DATE : ""}</td>
                    <td>{item.AMOUNT ? item.AMOUNT : ""}</td>
                    <td>{item.STS_ID == 20 ? item.wagered.totalWagered : ""}</td>
                    <td>{item.BONUS_ID ? item.BONUS_ID : ""}</td>
                    <td>{item.COMMENT ? item.COMMENT : ""}</td>
                    <td>{item.EXPIRY_DATE ? item.EXPIRY_DATE : ""}</td>
                    <td>{item.ISSUE_DATE ? item.ISSUE_DATE : ""}</td>
                    <td>{item.PROMO_CODE ? item.PROMO_CODE : ""}</td>
                    <td>{item.REWARD_DATE ? item.REWARD_DATE : ""}</td>
                    <td>{item.STS_ID ? item.STS_ID == 10 ? "Pending" : item.STS_ID == 20 ? "Active" : item.STS_ID == 30 ? "Expired" : "" : ""}</td> 
                  </tr>
                )
              })
            ) : (
              <></>
            )}
          </tbody>
        </Table>
      </div>
    </div>
  )
}
