
import React, { useEffect, useState, Fragment } from 'react'
import { OverlayTrigger, Table, Tooltip } from 'react-bootstrap'
import ButtonCustom from '../../../components/ButtonCustom'
import './index.scss'
import { useAuth } from '../../../Providers/AuthProvider';

export default ({ userId }) => {
    const [IpData, setIpData] = useState({})
    const { requestsToApi } = useAuth();

    useEffect(() => {
        getData()
    }, [])

    const getData = async () => {
        try {
            const response = await requestsToApi.get(`/misc/get-ip-rules?userstr=${userId}`)
            console.log('response', response);
            if (response?.data) {
                setIpData(response?.data.result);
                console.log('response?.data', response.data.result);

            }
        } catch (e) {
            console.log('error', e)
        }
    }

    const reset = async (type) => {
        try {
            let postData = {}
            switch (type) {
                case 'all':
                    if (IpData?.registerNumber) postData['registerNumber'] = 0
                    if (IpData?.forgotPassword) postData['forgotPassword'] = 0
                    if (IpData?.loginAttempts) postData['loginAttempts'] = 0
                    if (IpData?.verifyOTP) postData['verifyOTP'] = 0
                    break
                case 'registerNumber':
                    if (IpData?.registerNumber) postData['registerNumber'] = 0
                    break
                case 'forgotPassword':
                    if (IpData?.forgotPassword) postData['forgotPassword'] = 0
                    break
                case 'loginAttempts':
                    if (IpData?.loginAttempts) postData['loginAttempts'] = 0
                    break
                case 'verifyOTP':
                    if (IpData?.verifyOTP) postData['verifyOTP'] = 0
                    break
                default:
                    break
            }
            if (Object.keys(postData).length > 0) {
                console.log('postData', postData)
                const response = await requestsToApi.post(`/misc/set-ip-rules?userstr=${userId}`, postData)
                getData()
            }
        } catch (e) {
            console.log('error', e)
        }
    }
    console.log('IpData', IpData);

    return (
        <div className="ip-history">
            <div className="page-header-ip">
                <h1>User Unblock</h1>
                <ButtonCustom
                    className="btn btn-primary"
                    onClick={() => {
                        reset('all')
                    }}
                >
                    Reset All
                </ButtonCustom>
            </div>
            <div className="ip-history__content">
                {(
                    <Table striped hover variant="dark">
                        <thead>
                            <tr>
                                <th scope="col">Rule</th>
                                <th scope="col">Status</th>
                                <th scope="col">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Register Number</td>
                                <td>{IpData?.registerNumber}</td>
                                <td>
                                    <ButtonCustom
                                        className="btn btn-primary"
                                        onClick={() => {
                                            reset('registerNumber')
                                        }}
                                    >
                                        Reset
                                    </ButtonCustom>
                                </td>
                            </tr>
                            <tr>
                                <td>Forgot Password</td>
                                <td>{IpData?.forgotPassword}</td>
                                <td>
                                    <ButtonCustom
                                        className="btn btn-primary"
                                        onClick={() => {
                                            reset('forgotPassword')
                                        }}
                                    >
                                        Reset
                                    </ButtonCustom>
                                </td>
                            </tr>
                            <tr>
                                <td>Login Attempts</td>
                                <td>{IpData?.loginAttempts}</td>
                                <td>
                                    <ButtonCustom
                                        className="btn btn-primary"
                                        onClick={() => {
                                            reset('loginAttempts')
                                        }}
                                    >
                                        Reset
                                    </ButtonCustom>
                                </td>
                            </tr>
                            <tr>
                                <td>Verify OTP</td>
                                <td>{IpData?.verifyOTP}</td>
                                <td>
                                    <ButtonCustom
                                        className="btn btn-primary"
                                        onClick={() => {
                                            reset('verifyOTP')
                                        }}
                                    >
                                        Reset
                                    </ButtonCustom>
                                </td>
                            </tr>
                        </tbody>
                    </Table>
                )}
            </div>
        </div>
    )
}



// const httpRequest = async (url, method, body, signal) => {
//     const token = localStorage.getItem('token')
//     const response = await fetch(`https://invalid.saikiseva.com${url}`, {
//         method,
//         headers: {
//             'Content-Type': 'application/json',
//             authorization: token || '',
//         },
//         body: JSON.stringify(body),
//         signal,
//     })

//     const data = await response.json()
//     const headers = await response.headers
//     return { data, headers }
// }
