import React, { useEffect, useState } from "react"
import { Table } from "react-bootstrap"
import ButtonCustom from "../../../components/ButtonCustom"
import "./index.scss"
import { downloadCsv } from '../../../utils/createCsv'
import PaginationCustom from '../../../components/PaginationCustom'
import DateTimeRangePicker from '../../../components/DateTimeRangePicker'
import moment from 'moment/moment'
import { useAuth } from "../../../Providers/AuthProvider"
import { useStorage } from "../../../Providers/StorageProvider"
export default ({ userData }) => {
  const [historyIpData, setHistoryIpData] = useState({})
  const [allHistoryIpData, setAllHistoryIpData] = useState([])
  const [activePage, setActivePage] = useState(1)
  const [totalPage, setTotalPage] = useState(1)
  const {isLoading, setIsLoading } = useStorage()
  const now = new Date()
  const startOfToday = moment(new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0, 0))
  const endOfToday = moment(startOfToday).add(1, "days").subtract(1, "seconds")
  const [dateTimeRange, setDateTimeRange] = useState(null)
  const { requestsToApi } = useAuth();

  useEffect(() => {
    if (allHistoryIpData) {
      const filteredDates = allHistoryIpData.filter(({ loginDate }) => new Date(loginDate).getTime() >= new Date(dateTimeRange.start).getTime() && new Date(loginDate).getTime() <= new Date(dateTimeRange.end).getTime() )
      setHistoryIpData(filteredDates)
      setTotalPage(Math.ceil(filteredDates.length / 50))
      setActivePage(1)
    }
  }, [dateTimeRange]);

  useEffect(() => {
    if (userData?.name) {
    getData();
    }
  }, [userData])

  const getData = async () =>{
    try {
      setIsLoading(true)
      // todo need change API
      const response = await requestsToApi.get(`/misc/getUserActivity?memberCode=${userData?.name}&pageNumber=1`)
      if (response?.data) {
        setHistoryIpData(response?.data)
        setAllHistoryIpData(response?.data)
        setTotalPage(Math.ceil(response?.data?.length / 50))
        setDateTimeRange({
          start: moment(new Date(response?.data[0].loginDate)).subtract(15, "days"),
          end: moment(new Date(response?.data[0].loginDate)),
        })

      }
      setIsLoading(false)
    } catch (e) {
      console.log("error", e)
      setIsLoading(false)
    }
  }
  const dataCsv = () => {
     let data = historyIpData?.map(row =>
       ( {
          LoginDate: row?.loginDate?.replaceAll("-", "/"),
          IP: row?.ip,
          ISP: row?.isp,
          Country: row?.country,
          City: row?.city,
          State: row?.state
        })
      );
    return { data: data }
  }
  const paginate = (array, page_size, page_number) => {
    return array.slice((page_number - 1) * page_size, page_number * page_size);
  };

  return (
    <div className="ip-history">
      <div className="page-header-ip">
        <h1>IP history</h1>
        {!!allHistoryIpData?.length &&
          <div className="ip-history__header-row">
            {dateTimeRange && <DateTimeRangePicker
              dateTimeRange={dateTimeRange}
              onChangeFunc={result => {
                setDateTimeRange(result)
              }}
            />}
            <ButtonCustom color='cyan' onClick={() => downloadCsv(dataCsv())}>Download CSV</ButtonCustom>
          </div>
        }
      </div>
      {/*{isLoading && <LoadingSpinner />}*/}
      <div className="ip-history__content">
        { !isLoading && historyIpData?.length ?
          <div className="ip-history__content-row">
            <PaginationCustom
          activePage={activePage}
          changeActivePage={(el) => {
            setActivePage(el)
          }}
          totalPages={totalPage}
            />
          </div> : <></>
        }
        {!isLoading && historyIpData && historyIpData?.length ? (
          <Table striped hover variant="dark">
            <thead>
              <tr>
                <th scope="col">LoginDate</th>
                <th scope="col">IP</th>
                <th scope="col">ISP</th>
                <th scope="col">Country</th>
                <th scope="col">City</th>
                <th scope="col">State</th>
              </tr>
            </thead>
            <tbody>
              {paginate(historyIpData, 50, activePage)?.map((row) => (
                    <tr key={row?.date}>
                      <td className="td_time" scope="col">
                        {row?.loginDate?.replaceAll("-", "/")}
                      </td>
                      <td scope="col">{row?.ip}</td>
                      <td scope="col">{row?.isp}</td>
                      <td scope="col">
                        {row?.country}
                        <span className={`flag-icon flag-icon-${row?.country?.toLowerCase()}`}></span>
                      </td>
                      <td scope="col">{row?.city}</td>
                      <td scope="col">{row?.state}</td>
                    </tr>
              ))}
            </tbody>
          </Table>
        ) : !isLoading ? <>There have been no ip history for this period</> : <></>
        }
        {!isLoading && historyIpData?.length ?
        <PaginationCustom
          activePage={activePage}
          changeActivePage={(el) => {
            setActivePage(el)
          }}
          totalPages={totalPage}
        /> : <></>}
      </div>
    </div>
  )
}
