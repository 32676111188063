
import React, { useEffect, useState, useRef } from 'react'
import { Table } from 'react-bootstrap'
import { Select, Button, Input } from 'antd'
import { useAuth } from '../../Providers/AuthProvider'
import './index.scss'

const IpRules = () => {
    const [IpAddress, setIpAddress] = useState('')
    const [IpData, setIpData] = useState({})
    const [isLoading, setIsLoading] = useState(false)
    const { requestsToApi } = useAuth();

    useEffect(() => {
        getData()
    }, [])

    const getData = async () => {
        try {
            if (IpAddress.length == 0) return
            setIsLoading(true)
            const response = await requestsToApi.get(`/misc/get-ip-rules?ipstr=${IpAddress}`)

            setIsLoading(false)
            if (response?.data) {
                setIpData(response?.data.result)
            }
        } catch (e) {
            setIsLoading(false)
            console.log('error', e)
        }
    }

    const reset = async (type) => {
        try {
            let postData = {}
            switch (type) {
                case 'all':
                    if (IpData?.registerNumber) postData['registerNumber'] = 0
                    if (IpData?.forgotPassword) postData['forgotPassword'] = 0
                    if (IpData?.loginAttempts) postData['loginAttempts'] = 0
                    if (IpData?.verifyOTP) postData['verifyOTP'] = 0
                    break
                case 'registerNumber':
                    if (IpData?.registerNumber) postData['registerNumber'] = 0
                    break
                case 'forgotPassword':
                    if (IpData?.forgotPassword) postData['forgotPassword'] = 0
                    break
                case 'loginAttempts':
                    if (IpData?.loginAttempts) postData['loginAttempts'] = 0
                    break
                case 'verifyOTP':
                    if (IpData?.verifyOTP) postData['verifyOTP'] = 0
                    break
                default:
                    break
            }
            setIsLoading(true)
            if (Object.keys(postData).length > 0) {
                console.log('postData', postData)
                const response = await requestsToApi.post(
                    `/misc/set-ip-rules?ipstr=${IpAddress}`,
                    postData
                )
                getData()
            }
            setIsLoading(false)
        } catch (e) {
            setIsLoading(false)
            console.log('error', e)
        }
    }

    return (
        <>
            <div className="page-container">
                <div className="header">
                    <div className="header__title">
                        <h2>IP Rules</h2>
                    </div>
                </div>

                <div className="text-left data-grid"
                    style={{ display: 'flex', justifyContent: 'space-between', marginTop: '120px', color: "black" }}
                >
                    <Input type='text' placeholder='Enter IP Address' value={IpAddress}
                        onChange={(e) => {
                            setIpAddress(e.target.value)
                        }}
                        className='ant-input'

                    />

                    <Button type="primary" className="btn btn-primary ml-3" onClick={getData}>
                        Get
                    </Button>
                    <Button type="danger"
                        className="btn btn-primary"
                        onClick={() => {
                            reset('all')
                        }}
                    >
                        Reset All
                    </Button>
                </div>

                {<div className="data-grid">
                    <Table striped hover variant="dark">
                        <thead>
                            <tr>
                                <th scope="col">Rule</th>
                                <th scope="col">Status</th>
                                <th scope="col">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Register Number</td>
                                <td>{IpData?.registerNumber}</td>
                                <td>
                                    <Button type="danger"
                                        className="btn btn-primary"
                                        onClick={() => {
                                            reset('registerNumber')
                                        }}
                                    >
                                        Reset
                                    </Button>
                                </td>
                            </tr>
                            <tr>
                                <td>Forgot Password</td>
                                <td>{IpData?.forgotPassword}</td>
                                <td>
                                    <Button type="danger"
                                        className="btn btn-primary"
                                        onClick={() => {
                                            reset('forgotPassword')
                                        }}
                                    >
                                        Reset
                                    </Button>
                                </td>
                            </tr>
                            <tr>
                                <td>Login Attempts</td>
                                <td>{IpData?.loginAttempts}</td>
                                <td>
                                    <Button type="danger"
                                        className="btn btn-primary"
                                        onClick={() => {
                                            reset('loginAttempts')
                                        }}
                                    >
                                        Reset
                                    </Button>
                                </td>
                            </tr>
                            <tr>
                                <td>Verify OTP</td>
                                <td>{IpData?.verifyOTP}</td>
                                <td>
                                    <Button type="danger"
                                        className="btn btn-primary"
                                        onClick={() => {
                                            reset('verifyOTP')
                                        }}
                                    >
                                        Reset
                                    </Button>
                                </td>
                            </tr>
                        </tbody>
                    </Table>
                </div>
                }
            </div>
        </>
    )
}

export default IpRules
