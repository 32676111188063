import './index.scss'
import ChevronRightIcon from 'mdi-react/ChevronRightIcon'
import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useAuth } from '../../../Providers/AuthProvider'
import { Table } from 'react-bootstrap'
import StringToRecolorNumber from '../../../components/StringToRecolorNumber'
import { useStorage } from '../../../Providers/StorageProvider';
import { numberWithCommas } from '../../../utils/dataTransform'
import CurrentTime from '../../../components/CurrentTime'
import axios from "axios"
import RoundResults from "../../../components/RoundResults/RoundResults"
import moment from 'moment'


export default ({ userId, dateTimeRange: { start, end }, goBack, prevPageData, openedMarket }) => {
  const { userName } = useStorage();
  const history = useHistory();
  const [responseData, setResponseData] = useState();
  const [action,setAction] = useState(prevPageData.filter(item => item.market === openedMarket)[0].action)
  const [sums, setSums] = useState({
    backSubtotal: 0,
    laySubtotal: 0,
    commission: prevPageData.filter(item => item.market === openedMarket)[0].commission
  })

  // todo need check
  // const roundId = !action == "LIVE_CASINO_SETTLE" && responseData && responseData[0]?.marketId.split(".")[1];
  const roundId = action !== "LIVE_CASINO_SETTLE" && responseData && responseData[0]?.marketId.split(".")[1];

  const { requestsToApi } = useAuth();
  useEffect(async () => {
    try {
      if(action == "LIVE_CASINO_SETTLE"){
        const response = await requestsToApi.get(`/agency/${userName}/report/casino/bet-history?sessionId=${openedMarket}`)
        console.log(response);
        setResponseData(response.data.result)
      }
      else{
        const response = await requestsToApi.get(`/agency/${userName}/report/member-order-list?memberCode=${userId}&from=${start.valueOf()}&to=${end.valueOf()}&marketId=${openedMarket}&orderStatus=settled`)
        let backSubtotal = 0, laySubtotal = 0;
        response.data.result.forEach(item => {
          if (item.side === 0) backSubtotal = backSubtotal + item.memberWin
          else laySubtotal = laySubtotal + item.memberWin
        })
        setSums({ ...sums, backSubtotal, laySubtotal })
        setResponseData(response.data.result)
      }
    } catch (error) {
      console.log(error?.message)
    }
  }, [history.location.search])

  // const goBack = () => {
  //     const splitedURL = history.location.pathname.split("/")
  //     history.push(splitedURL.slice(0, splitedURL.length - 2).join('/') + "#pnl-statement")
  // }

  return (
    <div className="breackdown-statement">
      <div className="breackdown-statement__main">
        <div className="nav">
          <div className="downline-nav">
            <a className="no-href" onClick={goBack}>{"Account Statement"}</a>
            <span><ChevronRightIcon size={17} /></span>
            {
              action == "LIVE_CASINO_SETTLE" ?
              <i>{`Evolution - Session ${openedMarket}`}</i>
              :
            <i>{responseData && responseData.length>0 ? `${responseData[0].eventName} - ${responseData[0].marketName}` : "market"}</i>
            }
          </div>
        </div>

        <div className="content">
          <Table striped hover variant="dark" className="breackdown-statement-table">
            <thead>
              {
                  action == "LIVE_CASINO_SETTLE"?
                  <tr>
                    <th scope="col" className="text-align-left">LABEL_MEMBER_NAME</th>
                    <th scope="col" className="text-align-left">Placed</th>
                    <th scope="col" className="text-align-left">LABEL_GAME_TYPE</th>
                    <th scope="col" className="text-align-left">Selection</th>
                    <th scope="col" className="text-align-left">Bet ID</th>
                    <th scope="col" className="text-align-left">LABEL_STAKE</th>
                    <th scope="col" className="text-align-left">Profit/Loss	</th>
                    <th scope="col" className="text-align-right">Status</th>
                  </tr>
                  :
                <tr>
                  <th scope="col" className="text-align-left">Placed</th>
                  <th scope="col" className="text-align-left">Selection</th>
                  <th scope="col" className="text-align-left">Bet ID</th>
                  <th scope="col" className="text-align-left">IP Address</th>
                  <th scope="col" className="text-align-left">Type</th>
                  <th scope="col">Odds</th>
                  <th scope="col">Stake</th>
                  <th scope="col">Profit/Loss</th>
                  <th scope="col" className="text-align-left">Status</th>
                </tr>
              }
            </thead>
            <tbody>
              {responseData ? responseData.map(item => (
                  action == "LIVE_CASINO_SETTLE" ?
                    <tr>
                      <td className="text-align-left">{item.playerId}</td>
                      <td className="text-align-left">
                        {moment(item.placedOn).format("DD/MM/YYYY")}
                        <br></br>
                        {moment(item.placedOn).format("HH:mm:ss")}
                      </td>
                      <td className="text-align-left"></td>
                      <td className="text-align-left">{item.gameType}</td>
                      <td className="text-align-left">{item.transactionId}</td>
                      <td className="text-align-left">{item.stake}</td>
                      <td className="text-align-left"><StringToRecolorNumber>{item.payout - item.stake}</StringToRecolorNumber></td>
                      <td scope="col" className="text-align-right">
                        <div className={`${(item.payout - item.stake) < 0?"-negative":"-positive"}`}>{(item.payout - item.stake) < 0? "LOST":"WON"}</div>
                      </td>
                    </tr>
                  :
                  <tr key={item.firstMatchedTime}>

                  <td className="text-align-left">
                    <span>{<CurrentTime format='DD/MM/YYYY'>{item.betPlacedDate}</CurrentTime>}</span>
                    <span style={{ color: "#808080" }}> | {<CurrentTime format='HH:mm:ss'>{item.betPlacedDate}</CurrentTime>}</span>
                  </td>
                  <td className="text-align-left">
                    {item.marketBettingType === "ODDS" ?
                      item.selectionName :
                      item.line
                    }
                  </td>
                  <td className="text-align-left">{item.apolloBetId}</td>
                  <td className="text-align-left">{item.betPlacedIp}</td>
                  <td className="text-align-left">
                    {item.marketBettingType === "ODDS" ?
                      item.side === 0 ? "Back" : "Lay"
                      :
                      item.side === 0 ? "Yes" : "No"
                    }
                  </td>
                  <td>{item.averagePrice.toLocaleString(undefined, { maximumFractionDigits: 3, minimumFractionDigits: 3 })}</td>
                  <td>{numberWithCommas(item.totalSize)}</td>
                  <td><StringToRecolorNumber>{item.memberWin}</StringToRecolorNumber></td>
                  <td className={"text-align-left " + (item.outcome === "LOST" ? "-negative" : "-positive")}>{item.outcome}</td>
                </tr>

              )) : <></>}
            </tbody>
          </Table>
          {
              action == "LIVE_CASINO_SETTLE" ?
              <div class="total">
                <p class="total__block">
                  <span>NET_SESSION_TOTAL:</span>
                  <span class="total__value">60.00</span>
                </p>
              </div>
              :
          <div className="content__results-wrapper">
            {responseData && responseData[0]?.eventTypeId === "1444001" && roundId && <RoundResults roundId={roundId}/>}
            <table className="market-total">
              <tr>
                <td>Back subtotal:</td>
                <td>{numberWithCommas(sums.backSubtotal)}</td>
              </tr>
              <tr>
                <td>Lay subtotal:</td>
                <td>{numberWithCommas(sums.laySubtotal)}</td>
              </tr>
              <tr>
                <td>Market subtotal:</td>
                <td>{numberWithCommas(sums.backSubtotal + sums.laySubtotal)}</td>
              </tr>
              <tr>
                <td>Commission:</td>
                <td>{numberWithCommas(sums.commission)}</td>
              </tr>
              <tr>
                <td>Net Market Total:</td>
                <td className="market-total__sum">{numberWithCommas(sums.backSubtotal + sums.laySubtotal - sums.commission)}</td>
              </tr>
            </table>
          </div>
            }
        </div>
      </div>
    </div>
  )
}
