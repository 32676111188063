import { useStorage } from "../../Providers/StorageProvider"
import { numberWithCommas } from "../../utils/dataTransform"
import SwitchCustom from "../../components/SwitchCustom"
import "./index.scss"
import StringToRecolorNumber from "../../components/StringToRecolorNumber"
import { useEffect, useState } from "react"
import { useAuth } from "../../Providers/AuthProvider"

const BalanceTracker = () => {
  const { balance } = useStorage()
  const { requestsToApi } = useAuth()
  const [eventPT, setEventPT] = useState(false)
  const [indicator, setIndicator] = useState(false)
  const [reqTrigger, resetReqTrigger] = useState({})
  const { userName } = useStorage()

  const role = sessionStorage.getItem('role');

  const eventPtReq = async () => {
    try {
      const response = await requestsToApi.get(`agency/${userName}/agency-mgmt/is-event-pt-enable`)
      setIndicator(!indicator)
      setEventPT(response.data.result.isEventPtEnabled)
    } catch (error) {
      console.log(error?.message)
    }
  }

  useEffect(() => {
    // const timer = setTimeout(() => {
    //   resetReqTrigger({})
    // }, 20000)
    // return () => clearTimeout(timer)
  }, [indicator])

  useEffect(() => {
    eventPtReq()
  }, [reqTrigger])

  return (
    ((role !== "READ_ONLY" && role !== "VOID_INVALID" ) && (<div className="balance-tracker">
       <div className="balance-tracker__content">
        {/* <div className="status"> */}
        <dl className="status-list">
          <div>
            <div>
              <dt>Balance Down:</dt>
              <dd>
                <StringToRecolorNumber>{balance.cashBalance}</StringToRecolorNumber>
              </dd>
            </div>
            <div>
              <dt>Balance Up:</dt>
              <dd>
                <StringToRecolorNumber>{balance.totalBalance}</StringToRecolorNumber>
              </dd>
            </div>
          </div>
          <div>
            <div>
              <dt>Net Exposure:</dt>
              <dd>
                <StringToRecolorNumber>{balance.actualNetExposure}</StringToRecolorNumber>
              </dd>
            </div>
            <div>
              {" "}
              <dt>Available Credit:</dt>
              <dd>{numberWithCommas(balance.availableBalance)}</dd>
            </div>
          </div>
        </dl>
        {/* </div> */}
        <div className="agency-settings">
          <label className="agency-settings__label">Position Taking:</label>
          <SwitchCustom className="agency-settings__switch" />
        </div>
      </div>
    </div>))
  )
}
export default BalanceTracker
