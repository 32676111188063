import { Table } from "react-bootstrap"
import CurrentTime from "../../../components/CurrentTime"
import RadioCustom from "../../../components/RadioCustom"
import { numberWithCommas } from "../../../utils/dataTransform"
import moment from "moment"

export default ({ data, setTypeOfRequest, userLevel, typeOfRequest }) => {
  const isMaster = userLevel !== "Member"
  return (
    <div className="bet-list-report__current">
      <div className="current__controls">
        <RadioCustom name="currentData" status="user" onChange={() => setTypeOfRequest("matched")} checked>
          Matched
        </RadioCustom>
        <RadioCustom name="currentData" status="members" onChange={() => setTypeOfRequest("unmatched")}>
          Unmatched
        </RadioCustom>
      </div>
      <div className="current__body">
        <Table striped hover variant="dark">
          <thead>
            {isMaster ? (
              <tr>
                {["Member", "Placed", "Description", "In Play", "1-Click", "Type", "Placed"].map(title => (
                  <th key={Math.random()} scope="col" className="text-align-left">
                    {title}
                  </th>
                ))}
                {["Odds", "Stake", "Potential Profit", "Liability", "Agent PT"].map(title => (
                  <th key={Math.random()} scope="col">
                    {title}
                  </th>
                ))}
                {userLevel == "SMA" || userLevel == "MA" ? <th scope="col">MA PT</th> : <></>}
                {userLevel === "SMA" ? <th scope="col">CUS PT</th> : <></>}
              </tr>
            ) : (
              <tr>
                {["Placed", "Description", "IP Address", "Type"].map(title => (
                  <th key={Math.random()} scope="col" className="text-align-left">
                    {title}
                  </th>
                ))}
                {["Odds", "Stake", "Liability", "Potential Profit"].map(title => (
                  <th key={Math.random()} scope="col">
                    {title}
                  </th>
                ))}
              </tr>
            )}
          </thead>
          <tbody>
            {isMaster && data && data.pageRows && data.pageRows.length && data.pageRows.length ? (
              data.pageRows.map(item => (
                <tr key={item.apolloBetId}>
                  <td className="text-align-left">
                    <p>{item.loginName}</p>
                    <p>{`(${item.userName})`}</p>
                  </td>
                  <td className="text-align-left">
                    {<CurrentTime format="DD/MM/YYYY HH:mm:ss">{item.betPlacedDate}</CurrentTime>}
                  </td>
                  <td className="text-align-left">
                    <p>{item.eventName.replace(
                          "${HH:mm}",
                          moment.unix(item.marketTime / 1000).utcOffset(330).format("HH:mm"))}</p>
                    <p>{`${item.selectionName} - ${item.marketName}`}</p>
                    {item.marketType === "INNINGS_RUNS" &&
                    <p>{`Runs: ${item.line}`}</p>
                    }
                    <p>{`Bet ID ${item.apolloBetId}`}</p>
                    { typeOfRequest === "matched" &&
                      <p>
                      {"Matched Date "}
                        {<CurrentTime format="DD/MM/YYYY HH:mm:ss">{item.lastMatchedDate}</CurrentTime>}
                    </p>}
                  </td>
                  <td className="text-align-left">{item.inPlay ? "Y" : "N"}</td>
                  <td className="text-align-left">{item.oneClick ? "Y" : "N"}</td>
                  <td className="text-align-left">
                    {item.marketBettingType === "ODDS"
                      ? item.side === 0
                        ? "Back"
                        : "Lay"
                      : item.side === 0
                      ? "Yes"
                      : "No"}
                  </td>
                  <td className="text-align-left">{item.placedOn}</td>
                  <td>{item.averagePrice}</td>
                  <td>{numberWithCommas(item.sizeTotal)}</td>
                  <td>{numberWithCommas(item.profitLoss)}</td>
                  {item.side === 1 ? <td>{numberWithCommas(item.liability)}</td> : <td>-</td>}
                  <td>{item.agentPosition}</td>
                  {userLevel == "SMA" || userLevel == "MA" ? <td>{item.maPosition}</td> : <></>}
                  {userLevel === "SMA" ? <td>{item.smaPosition}</td> : <></>}
                </tr>
              ))
            ) : !isMaster && data ? (
              data.map(item => (
                <tr key={item.apolloBetId}>
                  <td className="text-align-left">
                    {<CurrentTime format="DD/MM/YYYY HH:mm:ss">{item.betPlacedDate}</CurrentTime>}
                  </td>
                  <td className="text-align-left">
                    <p>{item.eventName.replace(
                          "${HH:mm}",
                          moment.unix(item.marketTime / 1000).utcOffset(330).format("HH:mm"))}</p>
                    <p>{`${item.selectionName} - ${item.marketName}`}</p>
                    {/* <p>{`Bet ID ${item.apolloBetId}
                            l Matched: ${<CurrentTime format='DD/MM/YYYY HH:mm:ss'>{item.betPlacedDate}</CurrentTime>}`}</p> */}
                    <p>
                      {item.marketType === "INNINGS_RUNS" &&
                        <p>{`Runs: ${item.line}`}</p>
                      }                      Bet ID {item.apolloBetId} l Matched: &nbsp;
                      <CurrentTime format="DD/MM/YYYY HH:mm:ss">{item.betPlacedDate}</CurrentTime>
                    </p>
                  </td>
                  <td className="text-align-left">{item.betPlacedIp}</td>
                  <td>
                    {item.marketBettingType === "ODDS"
                      ? item.side === 0
                        ? "Back"
                        : "Lay"
                      : item.side === 0
                      ? "Yes"
                      : "No"}
                  </td>
                  <td>
                    {item.averagePrice.toLocaleString(undefined, {
                      maximumFractionDigits: 3,
                      minimumFractionDigits: 3
                    })}
                  </td>
                  <td>{numberWithCommas(item.sizeTotal)}</td>
                  <td>{item.liability ? item.liability : "-"}</td>
                  <td>{numberWithCommas(item.profitLoss)}</td>
                </tr>
              ))
            ) : (
              <></>
            )}
          </tbody>
        </Table>
      </div>
    </div>
  )
}
