import { Image } from "react-bootstrap"
import { useHelpStorage } from "../../../../Providers/HelpProvider"
import Img1Agent from "../../../../static/images/help/menu_member_listing_agent.png"
import Img1MA from "../../../../static/images/help/menu_agent_listing_ma.png"
import Img1SMA from "../../../../static/images/help/menu_ma_listing_sma.png"
import Img2Agent from "../../../../static/images/help/button_direct_downline_summary_agent.png"
import Img2MA from "../../../../static/images/help/button_direct_downline_summary_ma.png"
import Img2SMA from "../../../../static/images/help/button_direct_downline_summary_sma.png"
import Img3Agent from "../../../../static/images/help/dls_member_activity_agent.png"
import Img3MA from "../../../../static/images/help/dls_agent_activity_ma.png"
import Img3SMA from "../../../../static/images/help/dls_ma_activity_sma.png"
import Img4MA from "../../../../static/images/help/dls_agent_balance_ma.png"
import Img4SMA from "../../../../static/images/help/dls_ma_balance_sma.png"
import Img5Agent from "../../../../static/images/help/dls_member_bet_list_agent.png"
import Img5MA from "../../../../static/images/help/dls_agent_bet_list_ma.png"
import Img5SMA from "../../../../static/images/help/dls_ma_bet_list_sma.png"
import Img6 from "../../../../static/images/help/dls_member_betting_pnl_agent.png"
import Img7Agent from "../../../../static/images/help/dls_member_account_statement_agent.png"
import Img7MA from "../../../../static/images/help/dls_agent_account_statement_ma.png"
import Img7SMA from "../../../../static/images/help/dls_ma_account_statement_sma.png"
import Img8Agent from "../../../../static/images/help/dls_member_transfer_statement_agent.png"
import Img8MA from "../../../../static/images/help/dls_agent_transfer_statement_ma.png"
import Img8SMA from "../../../../static/images/help/dls_ma_transfer_statement_sma.png"
import Img9Agent from "../../../../static/images/help/dls_member_net_exposure_agent.png"
import Img9MA from "../../../../static/images/help/dls_agent_net_exposure_ma.png"
import Img9SMA from "../../../../static/images/help/dls_ma_net_exposure_sma.png"
import HelpMenuLink from "../../../../components/HelpMenuLink"

export default () => {
  const { childrenLevel, userLevel } = useHelpStorage()
  return (
    <div>
      <h1>Viewing a {userLevel === "agent" ? "Member" : "Downline User"}'s Summary</h1>
      <div className="body">
        <p className="shortdesc">
          This section describes how you can view the detailed summary of
          {userLevel === "agent" ? " a Member" : " one of your downline users"}.
        </p>

        <p>
          When you navigate to the summary of{" "}
          {userLevel === "agent" ? "a" : userLevel === "ma" ? "an Agent or" : "an MA, Agent or"} Member in the{" "}
          <strong>{childrenLevel} Listing</strong> pane, you can view the following information:
          <br />
          Activity,
          <br />
          {userLevel !== "agent" ? (
            <>
              Balance for Agency users,
              <br />
            </>
          ) : (
            ""
          )}
          Bet List,
          <br />
          Betting P&L{userLevel !== "agent" ? " for Members" : ""},<br />
          Account Statement,
          <br />
          Transfer Statement, and
          <br />
          Net Exposure.
        </p>
        <p>To access the downline summary, follow this procedure:</p>

        <p className="last-p">
          Navigate to the <strong>Agency Management</strong> &gt;&nbsp;
          <strong>{childrenLevel} Listing</strong> menu, and click their Login Name.
        </p>

        <Image src={userLevel === "agent" ? Img1Agent : userLevel === "ma" ? Img1MA : Img1SMA} />
        <br />
        <br />
        <Image src={userLevel === "agent" ? Img2Agent : userLevel === "ma" ? Img2MA : Img2SMA} />

        <p className="last-p">
          The user's <strong>Activity</strong> tab is displayed by default showing their turnover and P&L (including
          commissions and winnings) for today, in the last three, seven and 30 days and since their account was created.
        </p>

        <Image src={userLevel === "agent" ? Img3Agent : userLevel === "ma" ? Img3MA : Img3SMA} />

        {userLevel !== "agent" ? (
          <>
            <p className="last-p">
              If you are viewing the downline summary of an {userLevel === "sma" ? "MA or " : ""}Agent, you can click
              the <strong>Balance</strong> tab to display their balance.
            </p>
            <Image src={userLevel === "ma" ? Img4MA : Img4SMA} />
          </>
        ) : (
          ""
        )}

        <p className="last-p">
          Click the Bet List tab to display the {userLevel !== "agent" ? "user" : "Member"}'s bet list.
        </p>

        <Image src={userLevel === "agent" ? Img5Agent : userLevel === "ma" ? Img5MA : Img5SMA} />

        <p className="last-p">
          To learn more about filtering the bet list, see the <HelpMenuLink>Bet List</HelpMenuLink> section.
          <br />
          {userLevel !== "agent" ? "If you are viewing a Member's downline summary, you can " : ""}
          click the <strong>Betting P&L</strong> tab to view the Member's betting profit and loss over the selected date
          range.
        </p>

        <Image src={Img6} />

        <p className="last-p">
          Click the <strong>Account Statement</strong> tab to find out how the user's account balance changed over time.
        </p>

        <Image src={userLevel === "agent" ? Img7Agent : userLevel === "ma" ? Img7MA : Img7SMA} />

        <p>
          Click the <strong>Transfer Statement</strong> tab to view the transfers between the user and their upline or
          downline.
        </p>
        <Image src={userLevel === "agent" ? Img8Agent : userLevel === "ma" ? Img8MA : Img8SMA} />

        <p className="last-p">
          Click the <strong>Bonus Statement</strong> tab to view the bonuses between date range and menber code or
          branch id.
        </p>

        <p className="last-p">
          Click the <strong>Net Exposure</strong> tab to find out about the user's net exposure on each market where
          they have placed bets.
        </p>
        <Image src={userLevel === "agent" ? Img9Agent : userLevel === "ma" ? Img9MA : Img9SMA} />

        <p className="last-p">
          <strong>Parent topic:</strong> <HelpMenuLink>{`${childrenLevel} Management`}</HelpMenuLink>
        </p>
      </div>
    </div>
  )
}
