import { useEffect, useState, Fragment } from "react"
import { OverlayTrigger, Table, Tooltip } from "react-bootstrap"
import ButtonCustom from "../../components/ButtonCustom"
import { useAuth } from "../../Providers/AuthProvider"
import "./index.scss"
import StringToRecolorNumber from "../../components/StringToRecolorNumber"
import { useStorage } from "../../Providers/StorageProvider"
import CurrentTime, { getTime } from "../../components/CurrentTime"
import { downloadCsv } from "../../utils/createCsv"
import RadioCustom from "../../components/RadioCustom"
import DatePicker from "../../components/DatePicker"
import jwt_decode from "jwt-decode"
import moment from "moment"
import axios from "axios"

export default ({ userId }) => {
  const [bonusData, setBonusData] = useState({})
  const [bonusSummaryData, setBonusSummaryData] = useState({})
  const { requestsToApi } = useAuth()
  const { userName, setIsLoading, activeTimeSetting } = useStorage()
  const [filter, setFilter] = useState("All")
  const endOfToday = moment().endOf("day")
  const startOfToday = moment().startOf("day")
  const [dateTimeRange, setDateTimeRange] = useState({ start: startOfToday, end: endOfToday })
  const [FROM, setFROM] = useState(new Date().toISOString().split("T")[0])
  const [TO, setTO] = useState(new Date().toISOString().split("T")[0])
  const [loading, setLoading] = useState(false)
  const [status, setStatus] = useState(10)
  const [name, setName] = useState("")
  const [manager, setManager] = useState("")
  const [code, setCode] = useState(sessionStorage.getItem("USER_BRANCH_ID")?.toString())
  const [manager_id, setManager_id] = useState(sessionStorage.getItem("paymentSystemId")?.toString())
  const [pageNumber, setPageNumber] = useState(1);


  const getData = async () => {
    try {
      setIsLoading(true)
      setBonusData([])
      const token = sessionStorage.getItem("accessToken")
      const url = `/bonuses/getBonuseWagerReport`;
      const { master = 0 } = jwt_decode(token)
      // first 2 char only
      const sm_mc = master.toString().substring(0, 2) + "0101";
      const data = {
        REPORT_NAME: "bonus_wager_report",
        status,
        login_name: name,
        manager_id: manager_id,
        page_number: pageNumber,
      }
      // const response = await axios.post(axiosUrl, data, { headers: { Authorization: `${token}` } })
      const response = await requestsToApi.post(url, data)
      console.log("response", response)
      if (!!response.data.status && !!response.data.result) {
        setBonusData(response.data.result);
      }
      setIsLoading(false)
    } catch (e) {
      console.log("error", e)
      setIsLoading(false)
    }
  }

  const bonusValidation = async (status, bonusId, member_code) => {
    try {
      setIsLoading(true)
      const token = sessionStorage.getItem("accessToken")
      const url = `/bonuses/bonusValidation`;
      const data = {
        status: status,
        bonusId,
        member_code,
        manager_id
      }
      // const response = await axios.post(axiosUrl, data, { headers: { Authorization: `${token}` } })
      const response = await requestsToApi.post(url, data)
      console.log("response", response)
      if (!!response.data.status && !!response.data.result) {
        getData()
      }
      setIsLoading(false)
    } catch (e) {
      console.log("error", e)
      setIsLoading(false)
    }
  }

  useEffect(async () => {
    setFROM(new Date().toISOString().split("T")[0])
    setTO(new Date().toISOString().split("T")[0])
    // getData()
  }, [])

  const selectStatus = e => {
    // console.log(e.target.value)
    setStatus(Number(e.target.value))
  }

  const set_name = e => {
    // console.log(e.target.value)
    setName(e.target.value)
  }

  const set_manager = e => {
    // console.log(e.target.value)
    setManager(e.target.value)
  }

  const update = (row, status) => async () => {
    console.log("row", row)
    const confirmation = prompt("Please enter \"CONFIRM\" to " + (status == 10 ? "Hold" : status == 20 ? "Approve" : "Reject") + " this bonus wager report");
    if (confirmation !== "CONFIRM") return;
    bonusValidation(status, row.bonus_id, row.member_code)
  }


  return (
    <div className="transfer-statement-page">
      <div className="page-header">
        <div style={{ float: "left", display: "flex", width: "100%" }}>
          <h1>Bonus Wager Report</h1>
          <div className="bet-list-report__radios">
            {/* <RadioCustom name="category" status="REWARD_SUMMARY_REPORT" type="green" onChange={radioToggle} checked>
              Reword Summary Report
            </RadioCustom>
            <RadioCustom name="category" status="REWARD_REPORT" type="green" onChange={radioToggle}>
              Reword Report
            </RadioCustom> */}
          </div>
          <div className="row container">
            <div className="col-md-3">
              <div className="form-group">
                Status
                <select className="form-control" onChange={selectStatus}>
                  <option value="10" >Pending</option>
                  <option value="20" >Approved</option>
                  <option value="30" >Rejected</option>
                </select>
              </div>
            </div>

            <div className="col-md-3">
              <div className="form-group">
                Login User
                <input className="form-control" onChange={set_name} />
              </div>
            </div>

            {/* <div className="col-md-3">
              <div className="form-group">
                Manager ID
                <input className="form-control" onChange={set_manager} />
              </div>
            </div> */}

            <div className="col-md-3">
              <div className="form-group">
                Action :
                <button className="btn btn-primary btn-block" onClick={() => { setPageNumber(1); getData() }}>
                  Get
                </button>
              </div>
            </div>
            {bonusData.length > 0 ? (
              <div className="col-md-3">
                <div className="form-group">
                  Action :
                  <button className="btn btn-primary btn-block" onClick={() => { setPageNumber(pageNumber + 1); getData() }}>
                    Next page
                  </button>
                </div>
              </div>
            ) : <></>}

          </div>
        </div>
      </div>
      <div className="transfer-statement-page__content">
        {/* transferData - last chenge */}

        {bonusData.length ? (
          <Table striped hover variant="dark">
            <thead>
              <tr>
                <th className="headcol" >Action </th>
                <th>Status </th>
                <th>txn_no </th>
                <th>user_id </th>
                <th>avg_odds </th>
                <th>bonus_id </th>
                <th>created_at </th>
                <th>login_name </th>
                <th>manager_id </th>
                <th>promo_code </th>
                <th>updated_at </th>
                <th>member_code </th>
                <th>wagered_cap </th>
                <th>claim_amount </th>
                <th>total_wagered </th>
                <th>casino_wagered </th>
                <th>sports_wagered </th>
                <th>claim_available </th>
                <th>teenpatti_wagered </th>
              </tr>
            </thead>
            {bonusData.map((row, index) => {
              return (
                <tr>
                  <td className="headcol" >
                    {row.status == 10 ? (
                      <div style={{ display: "flex" }}>
                        <button className="orange" onClick={update(row, 10)} Tooltip="Hold" >
                          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-stopwatch" viewBox="0 0 16 16">
                            <path d="M8.5 5.6a.5.5 0 1 0-1 0v2.9h-3a.5.5 0 0 0 0 1H8a.5.5 0 0 0 .5-.5V5.6z" />
                            <path d="M6.5 1A.5.5 0 0 1 7 .5h2a.5.5 0 0 1 0 1v.57c1.36.196 2.594.78 3.584 1.64a.715.715 0 0 1 .012-.013l.354-.354-.354-.353a.5.5 0 0 1 .707-.708l1.414 1.415a.5.5 0 1 1-.707.707l-.353-.354-.354.354a.512.512 0 0 1-.013.012A7 7 0 1 1 7 2.071V1.5a.5.5 0 0 1-.5-.5zM8 3a6 6 0 1 0 .001 12A6 6 0 0 0 8 3z" />
                          </svg>
                        </button>
                        <button className="green" onClick={update(row, 20)} Tooltip="Approve" >
                          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check2-square" viewBox="0 0 16 16">
                            <path d="M3 14.5A1.5 1.5 0 0 1 1.5 13V3A1.5 1.5 0 0 1 3 1.5h8a.5.5 0 0 1 0 1H3a.5.5 0 0 0-.5.5v10a.5.5 0 0 0 .5.5h10a.5.5 0 0 0 .5-.5V8a.5.5 0 0 1 1 0v5a1.5 1.5 0 0 1-1.5 1.5H3z" />
                            <path d="m8.354 10.354 7-7a.5.5 0 0 0-.708-.708L8 9.293 5.354 6.646a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0z" />
                          </svg>
                        </button>
                        <button className="red" onClick={update(row, 30)} Tooltip="Rejct" >
                          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-circle" viewBox="0 0 16 16">
                            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                            <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                          </svg>
                        </button>
                      </div>
                    ) :
                      <></>
                    }
                  </td>
                  <td>{row.status == 10 ? "Pending" : row.status == 20 ? "Approved" : "Rejected"} </td>
                  <td>{row.txn_no} </td>
                  <td>{row.user_id} </td>
                  <td>{row.avg_odds} </td>
                  <td>{row.bonus_id} </td>
                  <td>{new Date(row.created_at + 'z').toLocaleString()} </td>
                  <td>{row.login_name} </td>
                  <td>{row.manager_id} </td>
                  <td>{row.promo_code} </td>
                  <td>{new Date(row.updated_at + 'z').toLocaleString()} </td>
                  <td>{row.member_code} </td>
                  <td>{row.wagered_cap} </td>
                  <td>{row.claim_amount} </td>
                  <td>{row.total_wagered} </td>
                  <td>{row.casino_wagered} </td>
                  <td>{row.sports_wagered} </td>
                  <td>{row.claim_available} </td>
                  <td>{row.teenpatti_wagered} </td>
                </tr>
              )
            })}
          </Table>
        ) : (
          <></>
        )}
      </div>
    </div>
  )
}