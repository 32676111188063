import "./index.scss"
import RadioCustom from "../../../components/RadioCustom"
import { Table } from "react-bootstrap"
import { useEffect, useState } from "react"
import { numberWithCommas } from "../../../utils/dataTransform"
import { useAuth } from "../../../Providers/AuthProvider"
import StringToRecolorNumber from "../../../components/StringToRecolorNumber"
import { useStorage } from "../../../Providers/StorageProvider"
import ButtonCustom from "../../../components/ButtonCustom"

export default ({ userId, userLevel,getWallets }) => {
  const { requestsToApi } = useAuth()
  const [responseData, setResponseData] = useState(null)
  const [notesResponse, setNotesResponse] = useState([]);
  const [note, setNote] = useState("")
  const [id, setId] = useState("");
  const systemId = sessionStorage.getItem("paymentSystemId");

  const [displayedData, setDisplayedData] = useState(null)
  const { userName: myUserName } = useStorage()

  const handleIdChange=()=>{

    const id=JSON.parse(localStorage.getItem("s_user"))?.id
    setId(id)
  }
  const tableTemplate = [
    ["win", "Win", true],
    ["comm", "Comm", true],
    ["pnl", "P&L", true],
    ["turnover", "Turnover", false]
  ]

  useEffect(async () => {
      handleIdChange()

    getData();
    getNoteData();


  }, [id])
  const getData = async() =>{
    try {

      const responce = await requestsToApi.get(`agency/${myUserName}/report/user-summary?dl=${userId}`)
      setResponseData(responce.data.result)
      setDisplayedData(responce.data.result.user);
      getWallets(responce.data.result.bonusWallets)
    } catch (error) {
      console.log(error?.message)
    }
  }
  const getNoteData = async()=>{
    try {
      const response = await  requestsToApi.get(
        `/getNotes?memberCode=${userId}`,
      )
      if (response?.data?.result?.length > 0) {
        setNotesResponse(response?.data?.result)
      }else {
        setNotesResponse([])
      }
    }catch (error) {
      setNotesResponse([])
      console.log(error?.message)
    }

  }
  const addNote = async()=>{
    try{
      const response = await requestsToApi.post(
        `addNotes`,
        {
          "note":note,
          "memberCode":userId,
          "userId": systemId
        }
      )
      if(response?.data?.success){
        setNote("");
        getNoteData();
      }
    }catch(e){
      console.log(e.message);
    }
  }

  const radioToggle = e => {
    setDisplayedData(responseData[e.target.attributes["status"].value])
  }

  const formatData = (num, recolor) => {
    return recolor ? <StringToRecolorNumber>{num}</StringToRecolorNumber> : <span>{numberWithCommas(num)}</span>
  }

  return (
    <div className="activity-report">
      <div className="activity-report__controls">
        {userLevel === "Member" ? (
          <></>
        ) : (
          <>
            <RadioCustom status="user" onChange={radioToggle} checked>
              Agent
            </RadioCustom>
            <RadioCustom status="members" onChange={radioToggle}>
              All Members
            </RadioCustom>
          </>
        )}
      </div>
      <div className="activity-body">
        <Table striped hover variant="dark">
          <thead>
            <tr>
              <th style={{ width: "6rem" }} scope="col"></th>
              <th scope="col">Today</th>
              <th scope="col">3 days</th>
              <th scope="col">7 days</th>
              <th scope="col">30 days</th>
              <th scope="col">Lifetime</th>
            </tr>
          </thead>
          <tbody>
            {displayedData ? (
              tableTemplate.map(([key, title, recolor]) => (
                <tr key={title}>
                  <th>{title}</th>
                  {["1", "3", "7", "30", "all"].map(fieldName => (
                    <td key={Math.random()}>{formatData(displayedData[key][fieldName], recolor)}</td>
                  ))}
                </tr>
              ))
            ) : (
              <></>
            )}
            {displayedData?(
              <tr>
                <th style={{ width: "6rem" }} scope="col">Percentage</th>
                <td scope="col"><span>{parseFloat(100*displayedData.pnl[1]/displayedData.turnover[1]).toFixed(2)}</span></td>
                <td scope="col"><span>{parseFloat(100*displayedData.pnl[3]/displayedData.turnover[3]).toFixed(2)}</span></td>
                <td scope="col"><span>{parseFloat(100*displayedData.pnl[7]/displayedData.turnover[7]).toFixed(2)}</span></td>
                <td scope="col"><span>{parseFloat(100*displayedData.pnl[30]/displayedData.turnover[30]).toFixed(2)}</span></td>
                <td scope="col"><span>{parseFloat(100*displayedData.pnl['all']/displayedData.turnover['all']).toFixed(2)}</span></td>
              </tr>
            ) : (
              <></>
            )}
          </tbody>
        </Table>
        <div className="notes-body">
          <div className="notes-body-card notes-heading">
            Notes
            {
              notesResponse.map(item=><div className="notes_card">
                <ul className="notes-heading" >{item.NOTE}</ul>
                <ul className="notes-details" style={{display:'flex', justifyContent:'space-between'}} ><div>{item.DATE_ADDED}</div>
                  <div>{item.CSA_NAME}</div>
                </ul>
              </div>)
            }
          </div>
          <div style={{minWidth: "40%", display:"flex", margin:"0px 0px 0px 10px", flexDirection:"column"}}>
            Add Note
            <textarea rows={3} placeholder="note" style={{color:"black", margin: "10px 0"}} value={note} onChange={(e)=> {setNote(e.target.value)}} />
            <ButtonCustom
              disabled={!!!note}
              onClick={()=>{
                addNote();
              }} >Add New</ButtonCustom>
          </div>
        </div>
      </div>
    </div>
  )
}
