import { useEffect, useState, Fragment } from "react"
import { OverlayTrigger, Table, Tooltip } from "react-bootstrap"
import ButtonCustom from "../../components/ButtonCustom"
import { useAuth } from "../../Providers/AuthProvider"
import "./index.scss"
import StringToRecolorNumber from "../../components/StringToRecolorNumber"
import { useStorage } from "../../Providers/StorageProvider"
import CurrentTime, { getTime } from "../../components/CurrentTime"
import { downloadCsv } from '../../utils/createCsv'
import RadioCustom from "../../components/RadioCustom"
import moment from "moment/moment"
import TextInputCustom from "../../components/TextInputCustom"

export default ({ userData }) => {
  const [reportsData, setReportsData] = useState(null);
  const [filteredData, setFilteredData] = useState(null);
  const [activeToggle, setActiveToggle] = useState("Deposit");
  const [requestIdInput, setRequestIdInput] = useState("");
  const { requestsToApi } = useAuth()
  const { setIsLoading } = useStorage();
  const { userName } = useStorage();

  const getRequest = async () => {
    try {
      setIsLoading(true)
      const response = await requestsToApi.get( `/agency/${userName}/report/dw?memberCode=${userData.name}&requestId=${requestIdInput.toString()}`)
      setReportsData(response.data.result.sort((a, b) => {if (a.createdAt > b.createdAt) return -1;}));
      // setReportsData(response.data.result);
      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
    }
  };

  useEffect(async () => {
    getRequest()
  }, [userData]);

  useEffect(async () => {
    if (reportsData) {
      if (requestIdInput) {
        setFilteredData(reportsData);
        setActiveToggle("")
      } else {
        setFilteredData(reportsData.filter(item => item.type ===  activeToggle))
      }
    }
  }, [reportsData, activeToggle]);

  const radioToggle = e => {
    setActiveToggle(e.target.attributes["status"].value)
    setRequestIdInput("")
  }

  return (
    <div className="dw-support-reports-page">
      <div className="dw-support-reports-page__header">
        <div className="dw-support-reports-page__header-input-wrapper">
          <TextInputCustom
            className="customer-reports__textInputCustom"
            onChange={(value) => setRequestIdInput(value)}
            placeholder="Request Id"
            color="white"
          >
            {requestIdInput}
          </TextInputCustom>
          <ButtonCustom onClick={() => {
            getRequest()
          }} color="cyan">Apply</ButtonCustom>
        </div>
        <div className="bet-list-report__radios">
              <RadioCustom name="category" status="Deposit" type="green" onChange={radioToggle} checked>
                Deposit
              </RadioCustom>
              <RadioCustom name="category" status="Withdrawal" type="green" onChange={radioToggle}>
                Withdrawal
              </RadioCustom>
        </div>
      </div>
      <div className="dw-support-reports-page__table">
        <ul className="dw-support-reports-page__table-header">
          <li>Request Id</li>
          <li>Status</li>
          <li>Type</li>
          <li>UTR no</li>
          <li>Amount</li>
          <li>Create Date</li>
          <li>Update Date</li>
          <li>IP</li>
          <li>{activeToggle === "Deposit" ? "Gateway" : "Account"}</li>
        </ul>
        <div className="dw-support-reports-page__table-body">
          {filteredData?.map((report, index) =>
            <ul key={report.requestId + index} className="dw-support-reports-page__table-body-row">
              <li>{report?.requestId}</li>
              <li>{report?.status}</li>
              <li>{report?.type}</li>
              <li>{report?.utr}</li>
              <li>{report?.amount}</li>
              <li className="dw-support-reports-page__table-body-row-date">
                <div>{moment(report?.createdAt)?.format("DD.MM.YY")}</div>
                <div className="time">{moment(report?.createdAt)?.format("LTS")}</div>
              </li>
              <li className="cdw-support-reports-page__table-body-row-date">
                {report?.lastUpdateTime &&
                  <div>
                    <div>{moment(report?.lastUpdateTime)?.format("DD.MM.YY")}</div>
                    <div className="time">{moment(report?.lastUpdateTime)?.format("LTS")}</div>
                  </div>}
              </li>
              <li>{report?.IP}</li>

              {activeToggle === "Deposit" ?
                <li className="customer-reports__table-body-row-date">
                  {report?.gateway &&
                    <div>
                      <div>{report.gateway?.name}</div>
                      <div>{report.gateway?.acNo}</div>
                      <div>{report.gateway?.IFSC}</div>
                      <div>{report.gateway?.bankName}</div>
                    </div>}
                </li>
              :
                <li className="customer-reports__table-body-row-date">
                  {report?.account &&
                    <div>
                      <div>{report.account?.accountNo}</div>
                      <div>{report.account?.accountHolderName}</div>
                      <div>{report.account?.branchName}</div>
                      <div>{report.account?.IFSC}</div>
                      <div>{report.account?.bankName}</div>
                    </div>}
                </li>
              }
            </ul>
          )}
        </div>
      </div>
    </div>
  )
}
