import "./index.scss"
import { useState } from "react"
import ButtonCustom from "../ButtonCustom"
import axios from "axios"
import ModalCustom from "../ModalCustom"
import AlertCustom from "../AlertCustom"

export default ({ visible = true, closeFunc, reloadFunc, data , action = false}) => {
  const [isDisabled, setDisabled] = useState(false);
  const [alertOptions, setAlertOptions] = useState({
    message: null,
    type: "green"
  })

  const handleClickApprove = async () => {
    setDisabled(true);

    try {
      // todo need API
      const token = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzZXNzaW9uSWQiOm51bGwsImFjY2Vzc1JpZ2h0cyI6bnVsbCwiYWxsb3dTaGFrdGlQcm8iOmZhbHNlLCJleHAiOjE2NjQ5MDg0NDgsImlhdCI6MTY2NDE4ODQ0OCwiaXNNYXN0ZXIiOmZhbHNlLCJsYXN0QWNjZXNzUmlnaHRzVXBkYXRlRGF0ZSI6bnVsbCwibGFzdExvZ2luVGltZSI6MTY2NDE4MTc1MzAyMywibGV2ZWwiOiJCQU5LX01BTkFHRVIiLCJsb2dpbkFwcCI6bnVsbCwibG9naW5Db3VudHJ5IjpudWxsLCJsb2dpbklQIjpudWxsLCJsb2dpbk5hbWUiOiI3Nzc3NzAwMDAxIiwibWFzdGVyIjpudWxsLCJtYXN0ZXJJZCI6MCwibmJmIjowLCJzdGF0dXMiOiJBQ1RJVkUiLCJ0aGVtZSI6bnVsbCwiYXJlUnVsZXNBcHByb3ZlZCI6bnVsbCwicm9sZSI6bnVsbCwiaG9zdCI6bnVsbCwidG5jQWNjZXB0ZWQiOnRydWUsInVzZXIiOiJiYW5rIG1hbmFnZXIiLCJ1c2VySWQiOjE4LCJjdXJyZW5jeSI6bnVsbCwicGF5bWVudFN5c3RlbUlkIjoxLCJwYXltZW50U3lzdGVtTmFtZSI6IlRFU1RfUFMiLCJwYXltZW50U3lzdGVtU0EiOlsiVDEiXX0.5PB9qNpyDhppJ2D0weqgEh8nfdaeH156_oXq--XOViQ"
      const response = await axios.post(`https://payments.gemexch.bet/api/Banking/reject-request`,
        {
          "remark": "Balance request is not valid",
          "requestId": data?.requestId
        },
        {headers: {"Authorization": `${token}`}});

      if (response.data.success) {
        setAlertOptions({
          message: response?.data?.result || "Request cancelled successfully",
          type: "green"
        });
        setTimeout(() => reloadFunc(), 500)
        setTimeout(() => closeFunc(), 1000)
      }

    } catch (error) {
      setDisabled(false);
      setAlertOptions({
        message: error?.response?.data?.result || "Something went wrong",
        type: "red"

      })
    }

  }

  return visible && (
    <ModalCustom visible className="modal-child-info" closeFunc={() => closeFunc()} width={"500px"}>
      <div className="reports-pop-up__body">
        <div className="reports-pop-up__title">Do you wish to cancel the {action === "W" ? "withdrawal" : "deposit"} request?</div>
        <div className="reports-pop-up__row-title">
          <div><p>User ID:</p> <span>{data?.loginName}</span></div>
          <div><p>Request ID:</p> <span>{data?.requestId}</span></div>
          <div><p>UTR: </p> <span>{data?.amount}</span></div>
        </div>
        <div className="reports-pop-up__row-buttons">
          <div className="reports-pop-up__row-buttons-wrapper">
            <ButtonCustom
              onClick={closeFunc}
              color={isDisabled ? "#444444" : "red"}
              disabled={isDisabled}
            >
              Cancel
            </ButtonCustom>
            <ButtonCustom
              onClick={handleClickApprove}
              color="cyan"
              disabled={isDisabled}
            >
              Reject request
            </ButtonCustom>
          </div>
        </div>
      </div>
      <AlertCustom timeout={3000} disableFunc={() => setAlertOptions({ ...alertOptions, message: null })} type={alertOptions.type}>
        {alertOptions.message}
      </AlertCustom>
    </ModalCustom>
  )
  return <></>
};
