import React, { useState, useContext, useMemo, useEffect } from "react"
import { createApi } from "../utils/requestToApi"
import jwt_decode from "jwt-decode"
import moment from "moment"
import { useHistory } from "react-router-dom"

export const AuthContext = React.createContext()
export const useAuth = () => useContext(AuthContext)

export const AuthProvider = ({ children }) => {
  const [authLoading, setAuthLoading] = useState(true)
  const [isAuthenticated, setIsAuthenticated] = useState(false)
  const [token, setToken] = useState(sessionStorage.getItem("accessToken"))
  // const [token, setToken] = useState('eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzZXNzaW9uSWQiOiI2M2RjZmI5NTE2ZjM3NTEwNWY3ZjM3ZDg4YzM5NjAwYzlkNWFiMjNiY2QzNDMyZjQwNTljYjRkYzc5ZTNlZWI2IiwiYWNjZXNzUmlnaHRzIjp7Ik1BTkFHRV9ET1dOTElORSI6IkZVTExfQUNDRVNTIiwiTUFOQUdFX1JJU0siOiJGVUxMX0FDQ0VTUyIsIlBUX1RPR0dMRSI6IkZVTExfQUNDRVNTIiwiUkVQT1JUUyI6IkZVTExfQUNDRVNTIiwiU1RBVEVNRU5UUyI6IkZVTExfQUNDRVNTIiwiVFJBTlNGRVIiOiJGVUxMX0FDQ0VTUyJ9LCJhbGxvd1NoYWt0aVBybyI6ZmFsc2UsImV4cCI6MTY2MjIxNDY4NiwiaWF0IjoxNjYyMjA3NDg2LCJpc01hc3RlciI6ZmFsc2UsImxhc3RBY2Nlc3NSaWdodHNVcGRhdGVEYXRlIjpudWxsLCJsYXN0TG9naW5UaW1lIjoxNjYyMjA3NDg2NTgyLCJsZXZlbCI6IlNNQSIsImxvZ2luQXBwIjoiQWdlbmN5IiwibG9naW5Db3VudHJ5IjoiSU4iLCJsb2dpbklQIjoiMi40OS43My40NyIsImxvZ2luTmFtZSI6ImIwMi51bmljb25iZXRzdWEiLCJtYXN0ZXIiOiJaMyIsIm1hc3RlcklkIjoxMTcsIm5iZiI6MTY2MjIwNzQ4Niwic3RhdHVzIjoiQUNUSVZFIiwidGhlbWUiOiJsb3R1cyIsImFyZVJ1bGVzQXBwcm92ZWQiOm51bGwsInJvbGUiOm51bGwsImhvc3QiOiJhZG1pbi5hdXJhMjQ3LmNvbSxhZG1pbi51bmljb25iZXQuY29tLHd3dy51bmljb25iZXQuY29tIiwidG5jQWNjZXB0ZWQiOnRydWUsInVzZXIiOiJiMDIudW5pY29uYmV0c3VhIiwidXNlcklkIjo2MjM5NTYsImN1cnJlbmN5IjpudWxsfQ.aweFSfxxGKWnfaU012HTf8OAIboiVZrJVPUO0jkGOng')


  const history = useHistory()

  const logout = () => {
    sessionStorage.clear()
    setIsAuthenticated(false)
  }
  //it's for agensy

  const requestsToApi = useMemo(() => {
    // return createApi(`https://support.funexch.com/api`, logout, token)
    // return createApi(`https://dadm.gemexch.bet/api`, logout, token)
    //  return createApi(`http://10.255.254.18:5002/api`, logout, token)
    // return createApi(process.env.REACT_APP_API_URL, logout, token)
    return createApi(`https://support.lotus365.in/api`, logout, token)
    //  return createApi(`/api`, logout, token)
    // return createApi(`http://10.255.254.18:5002/api`, logout, token)
    // return cr eateApi(`https://invalid.saikiseva.com/api`, logout, token)
  }, [token]);

  //it's for payments
  // const requestsToApi = useMemo(() => {
  //   return createApi(`https://payments.gemexch.bet/api`, logout, token)
  // }, [token]);

  const setAuthProcess = async () => {
    if (token) {
      const { exp = 0 } = jwt_decode(token)
      const curDate = moment().unix()
      setIsAuthenticated(exp >= curDate)
      // exp <= curDate
      //   ? setIsAuthenticated(false)
      //   : setIsAuthenticated(true)
    } else {
      setIsAuthenticated(false)
    }
    setAuthLoading(false)
  }

  useEffect(() => {
    setAuthProcess()
  }, [])

  const auth = (token, user = {}) => {
    setIsAuthenticated(true)
    setToken(token)
    sessionStorage.setItem("accessToken", token)
    history.push("/account/balance")
  }

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        setToken,
        requestsToApi,
        logout,
        auth
      }}
    >
      {authLoading ? <p>...Loading</p> : children}
    </AuthContext.Provider>
  )
}
